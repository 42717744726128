import NavBar from "../NavBar";
import Table from "./Table";
import React, {Component} from "react";
import inMemoryJwt from "../../inMemoryJwt";
import MainViewControls from "./MainViewControls";
import TableFilterModal from "./TableFilterModal";
import {withRouter} from "react-router-dom";
import {getRequest} from "../../requestHandler";


class MainView extends Component {
  #DEFAULT_MAX_IDEAS = 5;
  #DEFAULT_PAGE_INDEX = 0;
  #DEFAULT_SORT_BY = "created_asc";
  #DEFAULT_ASC_DESC = "created_asc";

  state = {
    fetchingIdeas: false,
    ideas: null,
    total: 0,
    pageIndex: this.#DEFAULT_PAGE_INDEX,
    availableFiltersLoaded: false,
    maxIdeas: this.#DEFAULT_MAX_IDEAS,
    sortBy: this.#DEFAULT_SORT_BY,
    ascDesc: this.#DEFAULT_ASC_DESC,
    ideaSearch: "",
    highlightText: "",
    searchChanged: false,
    showFilterModal: false,
    filterSettings: null,
    filterSettingsChanged: false
  }

  onUnload = () => {
    const state = {...this.props.location.state};
    delete state['ideas']
    delete state['availableLabels']
    this.props.history.replace({
      key: this.props.history.location.key,
      state,
      search: this.props.history.location.search
    });
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)
    if (this.props.location.search) {
      let urlSearchParams = new URLSearchParams(this.props.location.search);
      this.setState(
        {
          maxIdeas: parseInt(urlSearchParams.get('limit') ?? this.#DEFAULT_MAX_IDEAS),
          pageIndex: parseInt(urlSearchParams.get('offset') ?? this.#DEFAULT_PAGE_INDEX),
          sortBy: parseInt(urlSearchParams.get('sortBy') ?? this.#DEFAULT_SORT_BY),
          ideaSearch: urlSearchParams.get('query') ?? "",
          highlightText: urlSearchParams.get('query') ?? "",
        },
        () => {
          if (this.props.location.state?.ideas) {
            this.setState({
              ideas: this.props.history.location.state.ideas,
              total: this.props.history.location.state.total
            });
          } else {
            this.fetchFilteredIdeas();
          }
        }
      );
    } else {
      if (this.props.location.state?.ideas && this.props.location.state?.total) {
        this.setState({
          ideas: this.props.history.location.state.ideas,
          total: this.props.history.location.state.total
        });
      } else {
        this.fetchDateIdeas();
      }
    }

    if (this.props.history.location.state?.availableLabels === undefined) {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      await getRequest(
        `/ideas/labels`, config)
        .then(res => {
          let labels = res.data;
          Object.keys(labels).forEach(labelType => {
              if (labelType === "required_equipment") {
                labels[labelType].unshift("None");
              } else if (labelType !== "categories") {
                labels[labelType].unshift("Any");
              }
            }
          );
          this.setState({
            availableLabels: labels,
            availableFiltersLoaded: true,
          });
          this.props.history.replace({
            key: this.props.history.location.key,
            state: {
              ...this.props.history.location.state,
              availableLabels: labels,
              availableFiltersLoaded: true,
            },
            search: this.props.history.location.search
          });

        })
        .catch(err => {
          console.error(err.response?.data || err);
        })
    } else {
      this.setState({availableFiltersLoaded: true});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      if (this.props.location.search) {
        let urlSearchParams = new URLSearchParams(this.props.location.search);
        this.setState(
          {
            maxIdeas: parseInt(urlSearchParams.get('limit') ?? this.#DEFAULT_MAX_IDEAS),
            pageIndex: parseInt(urlSearchParams.get('offset') ?? this.#DEFAULT_PAGE_INDEX),
            sortBy: parseInt(urlSearchParams.get('sortBy') ?? this.#DEFAULT_SORT_BY),
            ideaSearch: urlSearchParams.get('query') ?? "",
          },
          () => {
            this.fetchFilteredIdeas();
          }
        );
      } else {
        this.fetchDateIdeas();
      }
    }
  }

  orderQueryParams = (params) => {
    const customSortOrder = [
      'query', 'is_outdoors', 'min_duration_hrs', 'max_duration_hrs', 'min_price', 'max_price', 'categories', 'suitable_times', 'suitable_weathers',
      'suitable_seasons', 'available_equipment', 'min_completion_rate', 'max_completion_rate', 'limit', 'offset',
      'sort_by'
    ]

    const orderedParamsList = new URLSearchParams();

    for (let key of customSortOrder) {
      if (params.has(key)) {
        orderedParamsList.append(key, params.get(key));
      }
    }
    return orderedParamsList
  }

  fetchDateIdeas = async () => {
    this.setState({fetchingIdeas: true});
    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};
    getRequest(
      `/ideas/?limit=${this.state.maxIdeas}&offset=${this.state.pageIndex * this.state.maxIdeas}`, config)
      .then(res => {
        this.setState({...res.data, searchChanged: false, filterSettingsChanged: false, fetchingIdeas: false},
          () => {
            this.setState({highlightText: this.state.ideaSearch})

            let historyState;
            if (this.props.history.location.state !== undefined) {
              historyState = JSON.parse(JSON.stringify(this.props.history.location.state));
            } else {
              historyState = {};
            }
            historyState['ideas'] = res.data['ideas'];
            historyState['total'] = res.data['total'];
            historyState['ideaSearch'] = this.state.ideaSearch;
            historyState['pageIndex'] = this.state.pageIndex;
            historyState['sortBy'] = this.state.sortBy;
            historyState['availableLabels'] = this.state.availableLabels;
            historyState['availableFiltersLoaded'] = this.state.availableFiltersLoaded;
            historyState['filterSettings'] = this.state.filterSettings;
            this.props.history.replace({
              key: this.props.history.location.key,
              state: historyState,
              search: this.props.history.location.search
            });
          }
        );
      })
      .catch(err => {
        console.error(err.response?.data || err);
      })
  }

  fetchFilteredIdeas = async () => {
    this.setState({fetchingIdeas: true});
    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};

    let urlSearchParams = new URLSearchParams(this.props.location.search);
    if (!urlSearchParams.has('offset')) {
      urlSearchParams.append('offset', (this.state.maxIdeas * this.state.pageIndex).toString());
    } else {
      urlSearchParams.set('offset', (this.state.maxIdeas * this.state.pageIndex).toString());
    }
    if (!urlSearchParams.has('limit')) {
      urlSearchParams.append('limit', this.state.maxIdeas.toString());
    }

    getRequest(
      `/ideas/search?${urlSearchParams.toString()}`, config)
      .then(res => {
          this.setState({...res.data, searchChanged: false, filterSettingsChanged: false, fetchingIdeas: false},
            () => {
              this.setState({highlightText: this.state.ideaSearch});

              let historyState;
              if (this.props.history.location.state !== undefined) {
                historyState = JSON.parse(JSON.stringify(this.props.history.location.state));
              } else {
                historyState = {};
              }
              historyState['ideas'] = res.data['ideas'];
              historyState['total'] = res.data['total'];
              historyState['ideaSearch'] = this.state.ideaSearch;
              historyState['pageIndex'] = this.state.pageIndex;
              historyState['sort_by'] = this.state.sortBy;
              historyState['availableLabels'] = this.state.availableLabels;
              historyState['availableFiltersLoaded'] = this.state.availableFiltersLoaded;
              historyState['filterSettings'] = this.state.filterSettings
              this.props.history.replace({
                key: this.props.history.location.key,
                state: historyState,
                search: this.props.history.location.search
              });
            }
          );
        }
      )
      .catch(err => {
        console.error(err.response?.data || err);
      })
  }

  handlePagerClick = async (pageNum) => {
    let urlSearchParams = new URLSearchParams(this.props.location.search);

    if (urlSearchParams.has('offset')) {
      if (pageNum > 0) {
        urlSearchParams.set('offset', pageNum.toString());
      } else {
        urlSearchParams.delete('offset');
      }
    } else {
      if (pageNum > 0) {
        urlSearchParams.append('offset', pageNum.toString());
      }
    }

    const orderedParams = this.orderQueryParams(urlSearchParams);

    this.props.history.push({
      pathname: `/`,
      state: this.props.location.state,
      search: "?" + orderedParams.toString(),
      fromState: this.props.location.state,
      fromSearch: this.props.location.search
    });
  }

  handleSearchChange = (e) => {
    this.setState({ideaSearch: e.target.value, searchChanged: true});
  }

  handleSearchSubmit = async () => {
    if (this.state.searchChanged) {
      let urlSearchParams = new URLSearchParams(this.props.location.search);
      const search = this.state.ideaSearch.trim();

      if (urlSearchParams.has('query')) {
        if (search.length > 0) {
          urlSearchParams.set('query', search.toString().trim());
        } else {
          urlSearchParams.delete('query');
        }
      } else {
        urlSearchParams.append('query', search.toString().trim());
      }

      if (urlSearchParams.has('offset')) {
        urlSearchParams.delete('offset');
      }

      const orderedParams = this.orderQueryParams(urlSearchParams);
      let queryString = "?" + orderedParams.toString()
      queryString = queryString.replace("%5B", "[")
      queryString = queryString.replace("%5D", "]")
      queryString = queryString.replace("%2C", ",")

      this.props.history.push({
        pathname: `/`,
        state: {
          availableLabels: this.props.history.location.state['availableLabels'],
          availableFiltersLoaded: true,
        },
        search: queryString
      });
    }
  }

  handleShowFilter = (bool) => {
    this.setState({showFilterModal: bool});
  }

  handleFilterSubmit = async (filterSettings) => {
    if (filterSettings) {
      let urlSearchParams = new URLSearchParams(this.props.location.search);

      if (urlSearchParams.has('offset')) {
        urlSearchParams.delete('offset');
      }
      Object.keys(filterSettings).forEach((key) => {
          if (filterSettings[key] === "" || (filterSettings[key].length === 0 && filterSettings["enable_" + key] !== true)) {
            urlSearchParams.delete(key);
          } else if (typeof filterSettings[key] === 'boolean' || typeof filterSettings[key] === 'number') {
            if (urlSearchParams.has(key)) {
              urlSearchParams.set(key, JSON.stringify(filterSettings[key]));
            } else {
              urlSearchParams.append(key, JSON.stringify(filterSettings[key]));
            }
          } else if (filterSettings["enable_" + key] === false) {
            urlSearchParams.delete(key);
          } else if (filterSettings[key].length > 0 || filterSettings["enable_" + key] === true) {
            if (urlSearchParams.has(key)) {
              if (filterSettings[key].length > 0 || filterSettings["enable_" + key] === true) {
                urlSearchParams.set(key, JSON.stringify(filterSettings[key]));
              } else {
                urlSearchParams.delete(key);
              }
            } else {
              urlSearchParams.append(key, JSON.stringify(filterSettings[key]));
            }
          }
        }
      )

      this.setState({showFilterModal: false});
      const orderedParams = this.orderQueryParams(urlSearchParams);

      let queryString = "?" + orderedParams.toString()
      queryString = queryString.replaceAll("%5B", "[")
      queryString = queryString.replaceAll("%5D", "]")
      queryString = queryString.replaceAll("%2C", ",")

      this.props.history.push({
        pathname: `/`,
        state: {
          availableLabels: this.props.history.location.state['availableLabels'],
          availableFiltersLoaded: true,
        },
        search: queryString
      });
    } else {
      this.setState({showFilterModal: false});
      this.props.history.push({
        pathname: `/`,
        state: {
          availableLabels: this.props.history.location.state['availableLabels'],
          availableFiltersLoaded: true,
        },
      });
    }
  }

  handleClearFilter = () => {
    let urlSearchParams = new URLSearchParams(this.props.location.search);
    const query = urlSearchParams.has('query') ? ("query=" + urlSearchParams.get('query')) : ''
    const sortBy = urlSearchParams.has('sort_by') ? ("sort_by=" + urlSearchParams.get('sort_by')) : ''
    let newSearch = query !== '' && sortBy !== '' ? [query, sortBy].join('&') : [query, sortBy].join('')
    newSearch = newSearch === '' ? '' : "?" + newSearch
    this.props.history.push({
      pathname: `/`,
      state: {
        availableLabels: this.props.history.location.state['availableLabels'],
        availableFiltersLoaded: true,
      },
      search: newSearch
    });
  }

  handleSort = () => {
    let urlSearchParams = new URLSearchParams(this.props.location.search);
    if (urlSearchParams.get('sort_by') === 'created_asc' || !urlSearchParams.has('sort_by')) {
      urlSearchParams.set('sort_by', 'created_desc');
      this.setState({
        ascDesc: "desc"
      });
    } else {
      urlSearchParams.set('sort_by', 'created_asc');
      this.setState({
        ascDesc: "asc"
      });
    }
    this.props.history.push({
      pathname: `/`,
      state: {
        availableLabels: this.props.history.location.state['availableLabels'],
        availableFiltersLoaded: true,
      },
      search: "?" + urlSearchParams.toString()
    });
  }

  render() {
    return (
      <div className="min-h-screen bg-gray-50">
        <NavBar handleSignOut={this.props.handleSignOut}/>
        <MainViewControls
          locationState={this.props.location.state}
          ideaSearch={this.state.ideaSearch}
          availableFiltersLoaded={this.state.availableFiltersLoaded}
          handleSearchChange={this.handleSearchChange}
          handleSearchSubmit={this.handleSearchSubmit}
          handleShowFilter={this.handleShowFilter}
          handleClearFilter={this.handleClearFilter}
          handleSort={this.handleSort}
          ascDesc={this.state.ascDesc}
        />
        <Table
          fetchingIdeas={this.state.fetchingIdeas}
          ideas={this.state.ideas}
          total={this.state.total}
          maxIdeas={this.state.maxIdeas}
          pageIndex={this.state.pageIndex}
          handlePagerClick={this.handlePagerClick}
          highlightText={this.state.highlightText}
        />
        <TableFilterModal
          open={this.state.showFilterModal}
          onRequestClose={() => this.handleShowFilter(false)}
          handleFilterSubmit={this.handleFilterSubmit}
          setAvailableFiltersLoaded={() => this.setState({availableFiltersLoaded: true})}
        />
      </div>
    )
  }
}

export default withRouter(MainView);
