import {Component} from "react";
import {postRequest} from "../../requestHandler";
import {withRouter} from "react-router-dom";

class ForgotPassword extends Component {
  state = {
    submitting: false,
    success: false
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.props.handleEmailChange(value);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitting: true});
    postRequest('/auth/reset-password', {
      email: this.props.email
    })
      .then(() => {
        this.setState({success: true, submitting: false});
      })
      .catch((err) => {
        console.error(err.response?.data || err);
        this.setState({submitting: false});
      });
  }

  render() {
    return (
      this.state.success === true ?
        <div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 mb-5">Reset Password</h2>
          <h5 className="text-center text-gray-900 mb-10">An email has been sent to {this.props.email} with instructions
            on how to reset your password.</h5>
          <p className="mt-2 text-center text-sm text-gray-600">
            <button className="font-medium text-purple-600 hover:text-purple-500"
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/auth/login`,
                      });
                    }}>
              Back to login
            </button>
          </p>
        </div>
        :
        <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
          <input type="hidden" name="remember" defaultValue="true"/>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                formNoValidate
                id="email-address"
                name="email"
                type="text"
                autoComplete="email"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={this.props.email}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={(this.state.submitting === true ? "opacity-50 cursor-not-allowed" : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
              disabled={this.state.submitting}
            >
              Send password reset link
            </button>
          </div>
          <div>
            <p className="mt-2 text-center text-sm text-gray-600">
              <button className="font-medium text-purple-600 hover:text-purple-500"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.history.push({
                          pathname: `/auth/login`,
                        });
                      }}>
                Back to login
              </button>
            </p>
          </div>
        </form>
    )
  }
}

export default withRouter(ForgotPassword);