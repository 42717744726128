import {SearchIcon, XCircleIcon} from "@heroicons/react/solid";
import React, {Component} from "react";
import {DropDownMenuOptions, NestedDropDownMenuOptions} from "./DropDownMenuOptions";

export default class MultiSelectDropDownMenu extends Component {

  componentDidMount() {
    this.searchInput.focus();
  }

  handleDropdownClick = (e) => {
    this.searchInput.focus();
    this.props.handleDropdownClick(e);
  }

  render() {
    return <>
      <div className="absolute z-50">
        <div
          id="dropdown"
          className="bg-white rounded-lg border-gray-400 p-1.5 border-2 shadow-md"
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              this.props.handleDropdown("");
            }
          }}
        >
          <div className="flex items-center">
            <SearchIcon className="h-4 w-4 mr-1 text-gray-700"/>
            <input
              autoFocus
              ref={(input) => {
                this.searchInput = input;
              }}
              className="focus:outline-none"
              placeholder="Search..."
              value={this.props.labelSearch}
              onChange={this.props.onChange}
            />
            {
              this.props.labelSearch !== ""
                ?
                <button
                  className="absolute text-gray-300 hover:text-gray-600 transition ease-in duration-100 right-2 cursor-default"
                  onClick={this.props.handleClearSearch}>
                  <XCircleIcon className="h-3.5 w-3.5"/>
                </button>
                :
                null
            }

          </div>
          <hr className="my-1"/>
          <div
            className="overflow-y-auto"
            style={{maxHeight: "30vh"}}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                this.props.handleDropdown("");
              }
            }}
          >

            {
              this.props.nested ?
                <NestedDropDownMenuOptions
                  availableLabels={this.props.availableLabels}
                  flattenedAvailableLabels={this.props.flattenedAvailableLabels}
                  handleDropdownClick={this.props.handleDropdownClick}
                  values={this.props.values}
                  labelSearch={this.props.labelSearch}
                />
                :
                <DropDownMenuOptions
                  availableLabels={this.props.availableLabels}
                  handleDropdownClick={this.handleDropdownClick}
                  values={this.props.values}
                  labelSearch={this.props.labelSearch}
                  showConfirmationModal={this.props.showConfirmationModal}
                />
            }
          </div>
        </div>
        <div style={{height: "50px"}}/>
      </div>
    </>;
  }
}