import jwt_decode from "jwt-decode";
import {getRequest} from "./requestHandler"

const inMemoryJWTManager = () => {

  const getToken = async () => {
    let authToken;
    let refreshToken;
    let location = "local";

    authToken = localStorage.getItem('accessToken');
    refreshToken = localStorage.getItem('refreshToken');
    if (authToken === null || refreshToken === null) {
      location = "session";
      authToken = sessionStorage.getItem('accessToken');
      refreshToken = sessionStorage.getItem('refreshToken');
    }

    if (authToken !== null && refreshToken !== null) {
      let decodedToken = jwt_decode(authToken);
      const dateNow = new Date();
      if (decodedToken.exp <= (dateNow.getTime() / 1000)) {
        const config = {headers: {Authorization: `Bearer ${refreshToken}`}}
        authToken = await getRequest('/auth/refresh', config)
          .then((res) => {
            const remember = (location === "local");
            setAccessToken(remember, res.data['access_token']);
            return res.data['access_token'];
          })
          .catch((err) => {
            console.error(err.response?.data || err);
            eraseTokens();
            return null;
          });
      }
    }
    return authToken;
  }

  const setAccessToken = (rememberMe, token) => {
    if (rememberMe) {
      localStorage.setItem('accessToken', token);
    } else {
      sessionStorage.setItem('accessToken', token);
    }
  };

  const setTokens = (rememberMe, data) => {
    if (rememberMe) {
      localStorage.setItem('accessToken', data['access_token']);
      localStorage.setItem('refreshToken', data['refresh_token']);
    } else {
      sessionStorage.setItem('accessToken', data['access_token']);
      sessionStorage.setItem('refreshToken', data['refresh_token']);
    }
  };

  const eraseTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshToken');
    return true;
  }

  return {
    eraseTokens,
    getToken,
    setTokens,
  }
};

export default inMemoryJWTManager();