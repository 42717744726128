import React, {useState} from "react";
import {AreaSeries, FlexibleWidthXYPlot, Hint, HorizontalGridLines, VerticalGridLines, XAxis, YAxis} from "react-vis";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import {generateIdeaCompletionDistData} from "./StatsTestDataUtil";
import RefreshDataButton from "./RefreshDataButton";

const tipStyle = {
  display: 'block',
  color: '#000',
  background: 'rgba(255,255,255,0.85)',
  alignItems: 'center',
};

const override = css`
  display: block;
  margin: 7.5rem auto auto auto;
`;

const queryKey = 'ideaCompletionDist';

export default function IdeaCompletionDistributionCard() {
  const fetchIdeaCompletionDistData = async () => {

    if (urlSearchParams.get('data-source') === "test") {
      return generateIdeaCompletionDistData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/ideas/completion-rate-distribution`, config)
        .catch((error) => {
          throw Error(error);
        });

      return res.data;
    }
  }

  const [hoveredCell, setHoveredCell] = useState(false);
  const [hoveredCellData, setHoveredCellData] = useState(false);
  const [firstClickCell, setFirstClickCell] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(queryKey, fetchIdeaCompletionDistData, {
    enabled: false,
  });

  if (isIdle) {
    refetch();
    return (
      <div className="lg:col-span-3 col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 348}}>
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="lg:col-span-3 col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 348}}>
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="lg:col-span-3 col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 348}}>
        <p>Total Views</p>
        <p className="text-gray-500" style={{marginTop: "7.5rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  const handleDrillDownClick = (_) => {
    if (firstClickCell === false) {
      setFirstClickCell({...hoveredCell, innerX: hoveredCellData.innerX});
    } else {
      const crMin = Math.min(firstClickCell.x, hoveredCell.x);
      const crMax = Math.max(firstClickCell.x, hoveredCell.x);
      history.push({
        pathname: `/`,
        search: `min_completion_rate=${crMin}&max_completion_rate=${crMax}`,
      });
    }
  }

  const handleOnNearestX = (value, e) => {
    if (value.x !== hoveredCell.x) {
      setHoveredCell(value);
      setHoveredCellData(e);
    }
  }
  return (
    <div className="lg:col-span-3 col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative"
         style={{minHeight: 348}}>
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={queryKey}/>
      <p>Idea Completion Rate</p>
      <div
        className="mx-4 cursor-pointer" style={{height: "calc(100% - 24px)"}}
        onMouseLeave={() => {
          setHoveredCell(false);
          setFirstClickCell(false);
        }}
        onMouseDown={handleDrillDownClick}
      >
        <FlexibleWidthXYPlot
          height={300}
          style={{touchAction: "none"}}
          yDomain={[0, data.max_y]}
        >
          <VerticalGridLines/>
          <HorizontalGridLines/>
          <XAxis tickFormat={d => d + "%"}/>
          <YAxis/>
          <AreaSeries
            color={"rgba(229,49,87,0.44)"}
            stroke="none"
            data={data.completion_rate_distribution}
            onNearestX={handleOnNearestX}
          />
          {firstClickCell && (
            <Hint
              value={hoveredCellData.innerX > firstClickCell.innerX
                ? firstClickCell
                : hoveredCell
              }
              align={{
                vertical: 'top',
                horizontal: 'right'
              }}
              style={{top: 0, transform: "translate(-1px, 10px)"}}
            >
              <div
                className="justify-end border-dashed rounded-lg border-r-2 border-l-2 border-black bg-blue-300 opacity-50"
                style={{
                  height: 250,
                  width: hoveredCellData.innerX > firstClickCell.innerX
                    ? hoveredCellData.innerX - firstClickCell.innerX + 2
                    : firstClickCell.innerX - hoveredCellData.innerX + 2
                }}/>
            </Hint>
          )}
          {firstClickCell && (
            <Hint
              value={firstClickCell}
              align={{
                vertical: 'top',
                horizontal: 'right'
              }}
              style={{transform: "translate(-5px, 5px)"}}
            >
              <div
                className="rounded-full justify-end"
                style={{height: 10, width: 10, backgroundColor: "#000000"}}/>
            </Hint>
          )}
          {hoveredCell && (
            <Hint value={hoveredCell} align={{
              vertical: 'top',
              horizontal: hoveredCell.x >= 50 ? 'left' : 'right'
            }}>
              <div
                className="items-end justify-end"
                style={{transform: hoveredCell.x >= 50 ? "translate(5px, 5px)" : "translate(-5px, 5px)"}}
              >
                <div className="px-4 py-1 rounded-lg border-gray-400 border-2 mx-2" style={tipStyle}>
                  {
                    firstClickCell === false
                      ?
                      <>
                        <b>Completion rate</b>
                        <p>{hoveredCell.y} ideas
                          {hoveredCell.x === 100 ? " = " : " >= "}
                          {hoveredCell.x}%</p>
                      </>
                      :
                      <>
                        <b>Drill-down select</b>
                        <p>Completion rate: {Math.min(firstClickCell.x, hoveredCell.x)}%
                          - {Math.max(firstClickCell.x, hoveredCell.x)}%</p>
                      </>
                  }
                </div>
                <div
                  className={"rounded-full justify-end " + (hoveredCell.x >= 50 ? "ml-auto" : "mr-auto")}
                  style={{height: 10, width: 10, backgroundColor: "#e11d48"}}/>
              </div>
            </Hint>
          )}
        </FlexibleWidthXYPlot>
      </div>
    </div>
  );
}