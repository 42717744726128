import {AspectRatio} from "react-aspect-ratio";
import {DiscreteColorLegend, Hint, makeVisFlexible, RadarChart} from "react-vis";
import React, {useState} from "react";
import {isMobile} from "react-device-detect";
import {generateLabelIndoorOutdoorDistData} from "./StatsTestDataUtil";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import RefreshDataButton from "./RefreshDataButton";

const FlexRadarChart = makeVisFlexible(RadarChart);

const tipStyle = {
  display: 'block',
  color: '#000',
  background: 'rgba(255,255,255,0.85)',
  alignItems: 'center',
  padding: '5px'
};

const radarStyle = {
  polygons: {
    strokeOpacity: 0.8,
    strokeWidth: 1,
    fillOpacity: 0.8
  },
  labels: {
    textAnchor: "middle",
    fontSize: 12,
    id: "radar-text"
  },
  axes: {
    line: {
      id: "radar-axis",
      fillOpacity: 1,
      strokeWidth: 0.5,
      strokeOpacity: 0.8
    },
    ticks: {
      fillOpacity: 0,
      strokeOpacity: 0
    },
    text: {}
  }
};

const override = css`
  display: block;
  margin: 10rem auto auto auto;
`;

export default function LabelIndoorOutdoorDistributionCard(props) {

  const fetchLabelIndoorOutdoorDistData = async () => {
    if (urlSearchParams.get('data-source') === "test") {
      return generateLabelIndoorOutdoorDistData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/labels/indoor-outdoor-distribution/${props.selectedLabelType}`, config)
        .catch((error) => {
          throw Error(error);
        });

      res.data.domains = [
        {
          name: res.data.labels[0],
          domain: [0, res.data.max_y],
          tickFormat: (t => {
            if (t < res.data.max_y && t > 0) {
              return Math.round(t);
            }
            return '';
          })
        },
        ...res.data.labels.slice(1, res.data.labels.length).map(category => {
          return {name: category, domain: [0, res.data.max_y], getValue: d => d[category]}
        })
      ];

      for (let i = 0; i < 6; i++) {
        res.data.labels.forEach(label => {
          res.data.indoor_outdoor_distribution[i][label] = res.data.max_y / 5 * (5 - i);
        });
      }

      return res.data;
    }
  }

  const [hoveredCell, setHoveredCell] = useState(false);
  const [mobileTappedCell, setMobileTappedCell] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(
    `${props.selectedLabelType}IndoorOutdoorDist`,
    fetchLabelIndoorOutdoorDistData,
    {enabled: false,}
  );

  if (isIdle) {
    refetch();
    return (
      <div
        className="col-span-2 md:col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Indoor/Outdoor Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="col-span-2 md:col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Indoor/Outdoor Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div
        className="col-span-2 md:col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Indoor/Outdoor Distribution by Label</p>
        <p className="text-gray-500" style={{marginTop: "10rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  const legendItems = [
    {
      title: "Indoors",
      color: data.indoor_outdoor_distribution.find(e => e.name === 'Indoors').stroke,
      strokeWidth: 5
    },
    {
      title: "Outdoors",
      color: data.indoor_outdoor_distribution.find(e => e.name === 'Outdoors').stroke,
      strokeWidth: 5
    },
  ];

  const handleMouseDown = (e) => {
    if (isMobile && mobileTappedCell.target === e.target && mobileTappedCell.taps === 1) {
      setMobileTappedCell({...mobileTappedCell, taps: mobileTappedCell.taps + 1});
    } else if (mobileTappedCell.target === e.target || hoveredCell !== false) {
      let search = '';
      if (hoveredCell.dataName === "Indoors" || hoveredCell.dataName === "Outdoors") {
        search += `is_outdoors=${hoveredCell.dataName === "Outdoors"}&`;
      }
      search += `categories=["${hoveredCell.domain}"]`;
      history.push({
        pathname: `/`,
        search,
      });
    }
  }

  const handleValueMouseOver = (value, e) => {
    if (isMobile) {
      setHoveredCell(value);
      setMobileTappedCell({target: e.event.target, taps: 1});
    } else {
      setHoveredCell(value);
    }
  }

  const handleValueMouseOut = () => {
    setHoveredCell(false);
    setMobileTappedCell(false);
  }

  return (
    <div
      className="col-span-2 md:col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative">
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt}
                         id={`${props.selectedLabelType}IndoorOutdoorDist`}/>
      <p>Indoor/Outdoor Distribution by Label</p>
      <DiscreteColorLegend orientation="horizontal" width={300} className="mx-auto mt-2" items={legendItems}/>
      <div style={{height: "calc(100% - 24px - 62px)"}}>
        <AspectRatio
          ratio="340/300"
          className="m-auto"
          style={{
            maxWidth: 340, top: "50%",
            transform: "translateY(-50%)"
          }}
          onClick={handleMouseDown}
          onDoubleClick={handleMouseDown}
        >
          <FlexRadarChart
            data={data.indoor_outdoor_distribution}
            margin={{top: 40, bottom: 40, right: 60, left: 60}}
            style={radarStyle}
            tickFormat={() => {
              return '';
            }}
            domains={data.domains}
            onValueMouseOver={handleValueMouseOver}
            onValueMouseOut={handleValueMouseOut}
            colorRange={["transparent"]}
            hideInnerMostValues={false}
            renderAxesOverPolygons={true}
          >
            {hoveredCell && (
              <Hint value={hoveredCell}>
                <div className="px-3 py-1 rounded-lg border-gray-400 border-2" style={tipStyle}>
                  <b>{hoveredCell.domain}</b><br/>
                  <span style={{color: "#9672F0C6", fontSize: 20}}>● </span>
                  Indoors: {data.indoor_outdoor_distribution[6][hoveredCell.domain]}<br/>
                  <span style={{color: "#E11D48C6", fontSize: 20}}>● </span>
                  Outdoors: {data.indoor_outdoor_distribution[7][hoveredCell.domain]}
                </div>
              </Hint>
            )}
          </FlexRadarChart>
        </AspectRatio>
      </div>
    </div>
  );
}