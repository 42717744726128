import './App.css';
import Auth from "./components/auth_view/Auth";
import {Component} from "react";
import inMemoryJwt from "./inMemoryJwt";
import MainView from "./components/main_view/MainView";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import IdeaView from "./components/idea_view/IdeaView";
import StatsView from "./components/stats_view/StatsView";


export default class App extends Component {
  state = {
    isAuth: null
  }

  componentDidMount() {
    inMemoryJwt.getToken()
      .then(res => {
        this.setState({isAuth: res !== null});
      })
  }

  handleAuth = (bool) => {
    this.setState({isAuth: bool});
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth">
            <Auth handleLogin={() => this.handleAuth(true)} isAuth={this.state.isAuth}/>
          </Route>
          <PrivateRoute exact path="/" isAuth={this.state.isAuth}>
            <MainView handleSignOut={() => this.handleAuth(false)}/>
          </PrivateRoute>
          <PrivateRoute exact path="/idea/:id" isAuth={this.state.isAuth}>
            <IdeaView handleSignOut={() => this.handleAuth(false)}/>
          </PrivateRoute>
          <PrivateRoute exact path="/stats" isAuth={this.state.isAuth}>
            <StatsView handleSignOut={() => this.handleAuth(false)}/>
          </PrivateRoute>
        </Switch>
      </Router>
    )
  }
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) =>
        rest.isAuth ? (
          children
        ) : rest.isAuth === null ? <div/> : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}