import NavBar from "../NavBar";
import {withRouter} from "react-router-dom";
import IdeaCard from "./IdeaCard";
import {ChevronLeftIcon, PencilIcon} from "@heroicons/react/solid";
import {Component} from "react";

class IdeaView extends Component {
  state = {
    edited: false,
    mode: this.props.match.params.id === "create" ? "create" : "view"
  }

  componentDidMount() {
    if (this.props.match.params.id !== "create") {
      this.setState({mode: this.props.location.state?.mode || "view"});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state?.mode !== this.props.location.state?.mode)
      this.setState({mode: this.props.location.state?.mode || "view"})
  }

  onEditClick = () => {
    return this.props.history.push({
      pathname: `/idea/${this.props.match.params.id}`,
      state: {...this.props.location.state, mode: "edit"}
    });
  }

  onSubmitEdit = () => {
    this.setState({edited: true, mode: "view"});
  }

  render() {
    return (
      <div className="min-h-screen bg-gray-50">
        <NavBar handleSignOut={this.props.handleSignOut}/>
        <div className="mx-auto flex justify-between max-w-5xl mt-7 px-3">
          <button
            className="flex pl-2 pr-4 max-w-min transition ease-in duration-200 py-1 bg-white shadow rounded-2xl whitespace-nowrap hover:bg-gray-200 items-center"
            onClick={() => {
              if (this.state.edited || this.props.location.state === undefined) {
                this.props.history.replace({
                  pathname: "/",
                  search: this.props.location.state?.fromSearch
                });
              } else {
                this.props.history.goBack();
              }
            }}>
            <ChevronLeftIcon className="h-4 w-4 mr-0.5"/>
            Go Back
          </button>

          {
            this.state.mode === "view"
              ?
              <button
                className="flex pl-3 pr-4 max-w-min transition ease-in duration-200 py-1 bg-white shadow rounded-2xl whitespace-nowrap hover:bg-gray-200 items-center"
                onClick={this.onEditClick}>
                <PencilIcon className="h-4 w-4 mr-0.5"/>
                Edit
              </button>
              :
              null
          }
        </div>
        <IdeaCard mode={this.props.location.state?.mode ?? this.state.mode} onSubmitEdit={this.onSubmitEdit}
                  id={this.props.match.params.id}/>
      </div>
    )
  }
}

export default withRouter(IdeaView);