import NavBar from "../NavBar";
import {withRouter} from "react-router-dom";
import React, {Component} from "react";
import 'react-vis/dist/style.css';
import IdeaStatsSection from "./IdeaStatsSection";
import LabelStatsSection from "./LabelStatsSection";

export const TEST_DATA_MAX_IDEAS = 300;
export const CATEGORY_CHOICES = ["Arts", "Chill", "Cute", "Exciting", "Food", "Fun", "Memories", "Messy", "Romantic"];
export const WEATHER_CHOICES = ["Sunny", "Rainy", "Cloudy", "Snowy", "Cold", "Hot"];
export const TIME_CHOICES = ["Morning", "Afternoon", "Daytime", "After Dark", "Evening", "Dinner", "Lunch", "Sunset"];
export const SEASON_CHOICES = ["Summer", "Winter", "August", "Spring", "Christmas", "Easter"]
export const EQUIPMENT_CHOICES = ["House", "Dog", "Tent", "Car"];

class StatsView extends Component {
  render() {
    return (
      <div className="min-h-screen bg-gray-50">
        <NavBar handleSignOut={this.props.handleSignOut}/>
        <div className="container mx-auto px-4 sm:px-8 max-w-7xl my-8">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            <IdeaStatsSection/>
            <LabelStatsSection/>
            {/*<SearchStatsSection/>*/}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(StatsView);