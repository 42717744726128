import {Component} from "react";
import {HeartIcon} from "@heroicons/react/solid";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import VerificationComplete from "./VerificationComplete";
import PasswordReset from "./PasswordReset";
import NoRoute from "./NoRoute";
import InvalidToken from "./InvalidToken";

class Auth extends Component {
  state = {
    email: ""
  };

  handleEmailChange = (email) => {
    this.setState({
      email
    });
  }

  handleLogin = () => {
    this.props.handleLogin();
    if (this.props.location.state !== undefined && 'from' in this.props.location.state) {
      return this.props.history.push({
        pathname: this.props.location.state.from.pathname,
      });
    } else {
      this.props.history.push({
        pathname: "/"
      });
    }
  }

  render() {
    return (
      <div className="absolute inset-0">
        <div className="min-h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          {
            this.props.isAuth === null
              ?
              null
              :
              !this.props.isAuth
                ?
                <div className="max-w-md w-full space-y-8">
                  <div>
                    <HeartIcon className="h-20 w-20 text-red-500 mx-auto justify-center" aria-hidden="true"/>
                    <h5 className="text-center text-gray-900">Admin Panel</h5>
                  </div>

                  <Switch>
                    <Route path={`${this.props.match.path}/login`}>
                      <Login handleEmailChange={this.handleEmailChange} handleLogin={this.handleLogin}
                             email={this.state.email}/>
                    </Route>

                    <Route path={`${this.props.match.path}/register`}>
                      <Register handleEmailChange={this.handleEmailChange} email={this.state.email}/>
                    </Route>

                    <Route path={`${this.props.match.path}/forgot-password`}>
                      <ForgotPassword handleEmailChange={this.handleEmailChange} email={this.state.email}/>
                    </Route>

                    <Route path={`${this.props.match.path}/verification-complete`}>
                      <VerificationComplete/>
                    </Route>

                    <Route path={`${this.props.match.path}/email-already-verified`}>
                      <InvalidToken text="Email address has already been verified!"/>
                    </Route>

                    <Route path={`${this.props.match.path}/reset-password`}>
                      <PasswordReset/>
                    </Route>

                    <Route path="*">
                      <NoRoute/>
                    </Route>
                  </Switch>
                </div>
                :
                <Redirect to={'/'}/>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Auth);