import React, {useState} from "react";
import {
  ChartLabel,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalBarSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis
} from "react-vis";
import {useHistory, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {useQuery} from "react-query";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {generateLabelPriceDistData} from "./StatsTestDataUtil";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import RefreshDataButton from "./RefreshDataButton";

const tipStyle = {
  display: 'block',
  color: '#000',
  background: 'rgba(255,255,255,0.85)',
  alignItems: 'center',
  zIndex: 1000
};

const override = css`
  display: block;
  margin: 10rem auto auto auto;
`;

export default function LabelPriceDistributionCard(props) {

  const fetchLabelPriceDistData = async () => {
    if (urlSearchParams.get('data-source') === "test") {
      return generateLabelPriceDistData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/labels/price-distribution/${props.selectedLabelType}`, config)
        .catch((error) => {
          throw Error(error);
        });
      return res.data;
    }
  }

  const [hoveredCell, setHoveredCell] = useState(false);
  const [mobileTappedCell, setMobileTappedCell] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(
    `${props.selectedLabelType}PriceDist`,
    fetchLabelPriceDistData,
    {enabled: false}
  );

  if (isIdle) {
    refetch();
    return (
      <div
        className="col-span-2 md:col-span-1 lg:col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Price Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="col-span-2 md:col-span-1 lg:col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Price Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div
        className="col-span-2 md:col-span-1 lg:col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Price Distribution by Label</p>
        <p className="text-gray-500" style={{marginTop: "10rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }
  const legendItems = [
    {title: "Free", color: data.color_palette[0], strokeWidth: 5},
    {title: "£", color: data.color_palette[1], strokeWidth: 5},
    {title: "££", color: data.color_palette[2], strokeWidth: 5},
    {title: "£££", color: data.color_palette[3], strokeWidth: 5},
    {title: "££££", color: data.color_palette[4], strokeWidth: 5},
  ]

  const handleDrillDownClick = (e) => {
    if (hoveredCell !== false) {
      if (isMobile) {
        if (mobileTappedCell.target === e.target && mobileTappedCell.taps === 1) {
          setMobileTappedCell({...mobileTappedCell, taps: mobileTappedCell.taps + 1});
        } else if (mobileTappedCell.target === e.target || hoveredCell !== false) {
          let search = '';
          search += `min_price=${hoveredCell.price}&categories=["${hoveredCell.y}"]`;
          history.push({
            pathname: `/`,
            search,
          });
        }
      } else {
        history.push({
          pathname: `/`,
          search: `min_price=${hoveredCell.price}&categories=["${hoveredCell.y}"]`,
        });
      }
    }
  }

  const onValueMouseOver = (value, e) => {
    if (isMobile) {
      setHoveredCell(value);
      setMobileTappedCell({target: e.event.target, taps: 1});
    } else {
      setHoveredCell(value);
    }
  }

  const onValueMouseOut = () => {
    setHoveredCell(false);
  }
  return (
    <div
      className="col-span-2 md:col-span-1 lg:col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative">
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt}
                         id={`${props.selectedLabelType}PriceDist`}/>
      <p>Price Distribution by Label</p>
      <DiscreteColorLegend orientation="horizontal" width={300} className="mx-auto my-2"
                           items={legendItems}/>
      <div
        className="mx-4" style={{height: "calc(100% - 24px)"}}
        onClick={handleDrillDownClick}
      >
        <FlexibleWidthXYPlot
          stackBy="x"
          height={300}
          yType="ordinal"
          margin={{left: 80}}
        >
          <VerticalGridLines/>
          <HorizontalGridLines/>
          <XAxis/>
          <YAxis/>
          <ChartLabel
            text="Number of ideas"
            className="alt-x-label"
            includeMargin={false}
            xPercent={isMobile ? 0.445 : 0.43}
            yPercent={1.19}
          />
          {data.price_distribution.map((data, i) =>
            <HorizontalBarSeries
              colorType="literal"
              key={`price-distribution-bar-series-${i}`}
              cluster="stack 1"
              data={data}
              style={{}}
              onValueMouseOver={onValueMouseOver}
              onValueMouseOut={onValueMouseOut}
            />
          )}
          {hoveredCell && (
            <Hint value={hoveredCell} align={{vertical: 'bottom', horizontal: 'left', zIndex: 100}}>
              <div className="px-3 py-1 rounded-lg border-gray-400 border-2" style={tipStyle}>
                <b>{hoveredCell.y}</b><br/>
                <div className="text-left mx-auto w-min whitespace-nowrap">
                  <span style={{color: legendItems[0].color, fontSize: 20}}>● </span>
                  Free: {data.price_distribution[0].find(e => e.y === hoveredCell.y).x}<br/>
                  <span style={{color: legendItems[1].color, fontSize: 20}}>● </span>
                  £: {data.price_distribution[1].find(e => e.y === hoveredCell.y).x}<br/>
                  <span style={{color: legendItems[2].color, fontSize: 20}}>● </span>
                  ££: {data.price_distribution[2].find(e => e.y === hoveredCell.y).x}<br/>
                  <span style={{color: legendItems[3].color, fontSize: 20}}>● </span>
                  £££: {data.price_distribution[3].find(e => e.y === hoveredCell.y).x}<br/>
                  <span style={{color: legendItems[4].color, fontSize: 20}}>● </span>
                  ££££: {data.price_distribution[4].find(e => e.y === hoveredCell.y).x}
                </div>
              </div>
            </Hint>
          )}
        </FlexibleWidthXYPlot>
      </div>
    </div>
  );
}