import React, {Component} from "react";
import 'react-aspect-ratio/aspect-ratio.css';
import LabelIndoorOutdoorDistributionCard from "./LabelIndoorOutdoorDistributionCard";
import LabelDurationDistributionCard from "./LabelDurationDistributionCard";
import LabelPriceDistributionCard from "./LabelPriceDistributionCard";

const labelTypes = {
  categories: "categories",
  suitableTimes: "suitable_times",
  suitableWeathers: "suitable_weathers",
  suitableSeasons: "suitable_seasons",
  requiredEquipment: "required_equipment"
};

export default class LabelStatsSection extends Component {
  state = {
    selectedLabelType: labelTypes.categories
  }

  handleChange = (event) => {
    this.setState({selectedLabelType: event.target.value});
  }

  render() {
    return <>
      <div className="lg:col-span-3 col-span-2 text-center text-gray-600 font-medium text-2xl mt-8"
           style={{fontSize: 36}}>
        LABEL STATS
      </div>

      <div className="lg:col-span-3 col-span-2 text-center text-gray-600 text-lg mb-3">
        <p className="font-medium">Select Label Type</p>
        <select
          className="border border-gray-300 bg-white shadow-sm my-2 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500"
          name="labelTypeSelect"
          value={this.state.selectedLabelType}
          onChange={this.handleChange}
        >
          <option value={labelTypes.categories}>Category</option>
          <option value={labelTypes.suitableTimes}>Suitable Time</option>
          <option value={labelTypes.suitableWeathers}>Suitable Weather</option>
          <option value={labelTypes.suitableSeasons}>Suitable Season</option>
          <option value={labelTypes.requiredEquipment}>Required Equipment</option>
        </select>
      </div>

      <LabelIndoorOutdoorDistributionCard
        selectedLabelType={this.state.selectedLabelType}
      />

      <LabelPriceDistributionCard
        selectedLabelType={this.state.selectedLabelType}
      />

      <LabelDurationDistributionCard
        selectedLabelType={this.state.selectedLabelType}
      />
    </>
  }
}