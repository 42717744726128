import {Component} from "react";

function PageNumberButton(props) {
  return (
    <button type="button"
            className={"w-full px-4 py-2 border-t border-b border-r text-base bg-white hover:bg-gray-100 "
            + (props.isIndex ? "text-purple-400 underline" : "")}
            onClick={() => props.handlePagerClick(props.pageNum)}
    >
      {props.pageNum + 1}
    </button>
  )
}

export default class TablePager extends Component {

  generatePageNumBtns = () => {

    const bufferSize = 2;

    let start = this.props.pageIndex - bufferSize;
    let end = this.props.pageIndex + bufferSize;


    if (start < 0) {
      end -= start;
    }

    if (end > (this.props.total / this.props.maxIdeas) - 1) {
      end = Math.ceil(this.props.total / this.props.maxIdeas) - 1
      start = end - bufferSize * 2
    }

    if (start < 0) {
      start -= start;
    }


    const pageNumbers = [];

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    return (
      pageNumbers.map(number =>
        <PageNumberButton
          key={number}
          pageNum={number}
          isIndex={this.props.pageIndex === number}
          handlePagerClick={this.props.handlePagerClick}
        />
      )

    )
  }

  render() {
    return <div className="flex items-center">
      <button type="button"
              className={"w-full p-4 border text-base rounded-l-xl bg-white " + (this.props.pageIndex > 0 ? "text-gray-600 hover:bg-gray-100" : "text-gray-400 bg-gray-50 cursor-default")}
              onClick={() => {
                if (this.props.pageIndex > 0) {
                  this.props.handlePagerClick(this.props.pageIndex - 1);
                }
              }}
      >
        <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
          </path>
        </svg>
      </button>

      {this.generatePageNumBtns()}

      <button type="button"
              className={"w-full p-4 border-t border-b border-r text-base rounded-r-xl bg-white " + (this.props.pageIndex < Math.ceil(this.props.total / this.props.maxIdeas) - 1 ? "text-gray-600 hover:bg-gray-100" : "text-gray-400 bg-gray-50 cursor-default")}
              onClick={() => {
                if (this.props.pageIndex < Math.ceil(this.props.total / this.props.maxIdeas) - 1) {
                  this.props.handlePagerClick(this.props.pageIndex + 1);
                }
              }}
      >
        <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
          </path>
        </svg>
      </button>
    </div>;
  }
}