import Modal from "react-modal";
import {Component} from "react";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(107, 114, 128, 0.4)',
    zIndex: 100
  },
  content: {
    borderRadius: "0.5rem",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default class NewLabelConfirmationModal extends Component {
  render() {
    return <Modal
      isOpen={this.props.open}
      onRequestClose={this.props.onRequestClose}
      ariaHideApp={false}
      id="dropdown"
      style={customStyles}
    >
      <div className="text-center w-full mx-auto py-6">
        <h2 className="text-xl">
          Are you sure you want to create a new
          <br/>
          <span className="font-bold text-indigo-500">{this.props.title.toLowerCase()} </span>
          label with value
          <span
            className="font-bold text-indigo-500"> {this.props.labelSearch.charAt(0).toUpperCase() + this.props.labelSearch.slice(1)}</span>
          ?
        </h2>
        <p className="text-red-700 text-sm mt-2">{this.props.error}</p>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className="mt-6 inline-flex">
            <button
              autoFocus
              type="button"
              className="py-2 px-3 bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              onClick={this.props.handleCreateLabel}
            >
              Continue
            </button>
            <button
              type="button"
              className="ml-5 py-2 px-3 hover:bg-gray-300 focus:ring-indigo-400 focus:ring-offset-gray-200 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              onClick={this.props.hideConfirmationModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>;
  }
}