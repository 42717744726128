import axios from "axios";


let serverHost;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  serverHost = "http://localhost:8080";
} else {
  serverHost = "https://untitled-dating-app.herokuapp.com";
}

export const getRequest = async (route, config) => {
  return await axios.get(serverHost + route, config);
}

export const postRequest = async (route, body, config) => {
  return await axios.post(serverHost + route, body, config);
}

export const putRequest = async (route, body, config) => {
  return await axios.put(serverHost + route, body, config);
}