import {Circle, GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps"
import {Component} from "react";
import {compose, withProps} from "recompose";
import {geolocated} from "react-geolocated";
import mapStyles from './mapStyles.json';

const CustomMarkers = (props) => {
  return <>
    {
      props.suggested_places.map(place =>
        <Marker
          key={place.place_id + "_icon"}
          defaultIcon={{
            path: "m6.986,0q2.906,0 4.945,2.039t2.039,4.945q0,1.453 -0.727,3.328t-1.758,3.516t-2.039,3.07t-1.711,2.273l-0.75,0.797q-0.281,-0.328 -0.75,-0.867t-1.688,-2.156t-2.133,-3.141t-1.664,-3.445t-0.75,-3.375q0,-2.906 2.039,-4.945t4.945,-2.039l0.002,0z",
            fillColor: props.fillColor,
            fillOpacity: 1,
            strokeColor: props.strokeColor,
            strokeWeight: 2,
            rotation: 0,
            scale: 2,
            anchor: new window.google.maps.Point(7, 20),
          }}
          defaultPosition={{
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng
          }}
          onMouseUp={() => console.log(place.name)}
        />
      )
    }
    {
      props.suggested_places.map(place =>
        <Marker
          key={place.place_id}
          defaultIcon={{
            url: place.icon,
            scaledSize: new window.google.maps.Size(16, 16),
            anchor: new window.google.maps.Point(8, 33),
            color: "OrangeRed"
          }}
          defaultPosition={{
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng
          }}
          onMouseUp={() => console.log(place.name)}
        />
      )
    }
  </>
}

class MyMapComponent extends Component {
  state = {
    draggingMarker: false
  }

  render() {
    return (
      this.props.coords
        ?
        <GoogleMap
          defaultZoom={10}
          defaultCenter={{lat: this.props.coords?.latitude, lng: this.props.coords?.longitude}}
          defaultOptions={{
            streetViewControl: false,
            mapTypeControl: false,
            styles: mapStyles
          }}
          onClick={this.props.updateMarkerPos}
          clickableIcons={false}
        >
          {
            this.props.markerPos !== null
              ? <Marker
                position={this.props.markerPos}
                draggable
                onDragStart={() => this.setState({draggingMarker: true})}
                onDrag={this.props.updateMarkerPos}
                onDragEnd={(e) => this.setState({draggingMarker: false})}
              />
              : null
          }
          {
            this.props.markerPos !== null
              ? <Circle
                center={this.props.markerPos}
                radius={this.props.radius}
                defaultOptions={{
                  strokeColor: "CornflowerBlue",
                  strokeOpacity: this.state.draggingMarker ? 0.5 : 1,
                  fillColor: "teal",
                  fillOpacity: this.state.draggingMarker ? 0.07 : 0.15,
                  clickable: false
                }}
              />
              : null
          }
          <CustomMarkers
            suggested_places={this.props.suggested_places.nearby_results}
            fillColor="MediumAquaMarine"
            strokeColor="MediumSeaGreen"
          />
          <CustomMarkers
            suggested_places={this.props.suggested_places.extended_results}
            fillColor="Tomato"
            strokeColor="OrangeRed"
          />
        </GoogleMap>
        :
        null
    )
  }
}

export default compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCo4KFh-u2jm181GOlRUB2N6A3PhrRXplg&v=3.exp&libraries=geometry,drawing",
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div className="mt-4 rounded-lg" style={{height: `550px`}}/>,
    mapElement: <div className="rounded-lg" style={{height: `100%`}}/>
  }),
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 10000,
      timeout: 5000
    },
    userDecisionTimeout: 5000,
  }),
  withScriptjs,
  withGoogleMap
)(MyMapComponent)