import {Component} from "react";
import {Range} from "react-range";

export default class RangeField extends Component {
  state = {
    value: 0.3
  }

  componentDidMount() {
    this.setState({value: (this.props.value / this.props.max) ** (1 / (2 * Math.E))})
  }

  onChange = (e) => {
    this.setState({value: e[0]})
    this.props.onChange(this.props.max * (e[0] ** (2 * Math.E)))
  }

  render() {
    return <div className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className}>
      <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">{this.props.title}</h4>
      {
        this.props.mode === "view"
          ?
          <p>{this.props.value}</p>
          :
          <div className="flex items-center">
            <input
              type="number"
              className="bg-transparent focus:bg-white focus:outline-none hover:bg-gray-200 transition ease-in duration-100 rounded resize-none"
              placeholder="?"
              step={this.props.step / 2}
              min={this.props.min}
              max={this.props.max}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
            />
            <Range
              step={0.01}
              min={0.3}
              max={1}
              values={[this.state.value]}
              onChange={this.onChange}
              renderTrack={({props, children}) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    backgroundColor: '#ccc'
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({props}) => (
                <div
                  className="outline-none bg-gray-400 focus-visible:bg-gray-600"
                  {...props}
                  style={{
                    ...props.style,
                    height: '10px',
                    width: '10px',
                  }}
                />
              )}
            />
          </div>
      }
    </div>;
  }
}