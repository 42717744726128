import React from "react";
import ViewedDateIdeasListItem from "./ViewedDateIdeasListItem";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import {generateIdeaListData} from "./StatsTestDataUtil";
import {isMobile} from "react-device-detect";
import RefreshDataButton from "./RefreshDataButton";

const override = css`
  display: block;
  margin: 10.5rem auto auto auto;
`;

const queryKey = 'ideaListStats';

export default function ViewedDateIdeasListCard(props) {
  const fetchIdeaList = async () => {
    if (urlSearchParams.get('data-source') === "test" || isMobile) {
      return generateIdeaListData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/ideas/`, config)
        .catch((error) => {
          throw Error(error);
        });

      return res.data.ideas;
    }
  }

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(queryKey, fetchIdeaList, {
    enabled: false,
  });

  if (isIdle) {
    refetch();
    return (
      <div
        className="col-span-1 hidden lg:block row-span-4 rounded-lg py-3 px-2 text-center bg-white filter card-shadow">
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="col-span-1 hidden lg:block row-span-4 rounded-lg py-3 px-2 text-center bg-white filter card-shadow">
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div
        className="col-span-1 hidden lg:block row-span-4 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative">
        <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={queryKey}/>
        <p>Total Views</p>
        <p className="text-gray-500" style={{marginTop: "10.5rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  return (
    <div
      className="col-span-1 hidden lg:block row-span-4 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative">
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={queryKey}/>
      <p>Ideas</p>
      <ul
        className="flex flex-col divide divide-y overflow-y-auto rounded-xl scrollbar scrollbar-thin scrollbar-thumb-gray-100 hover:scrollbar-thumb-gray-200 scrollbar-thumb-rounded-full"
        style={{minHeight: 375, maxHeight: props.showSlider ? 436 : 400}}>
        {data.map((idea, i) =>
          <ViewedDateIdeasListItem
            key={`date-idea-${i}`}
            title={idea['title']}
            views={idea['views']}
            labels={idea['labels']}
            id={idea['id']}
            completionRate={idea['completion_rate']}
          />
        )}
      </ul>
    </div>
  );
}