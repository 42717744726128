import {Component} from "react";
import {postRequest} from "../../requestHandler";
import {withRouter} from "react-router-dom";

class Register extends Component {
  state = {
    name: "",
    password: "",
    confirmPassword: "",
    error: null,
    submitting: false,
    success: false
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "email") {
      this.props.handleEmailChange(value);
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitting: true});
    postRequest('/auth/register', {
      name: this.state.name,
      email: this.props.email,
      password: this.state.password
    })
      .then(() => {
        this.setState({success: true, submitting: false});
      })
      .catch((e) => {
        let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
        if (e.response.data?.['code'] === "VALIDATION_ERROR") {
          this.setState({
            error: e.response.data['invalid_data']
          });
        } else if (e.response.data?.['code'] === "EMAIL_EXISTS") {
          error['email'] = [e.response.data['message']];
          this.setState({error});
        } else {
          console.error(e.response.data);
        }
        this.setState({submitting: false});
      });
  }

  render() {
    return (
      this.state.success === true ?
        <div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 mb-5">Registration success!</h2>
          <h5 className="text-center text-gray-900 mb-10">A verification link has been sent to your email.</h5>
          <p className="mt-2 text-center text-sm text-gray-600">
            <button className="font-medium text-purple-600 hover:text-purple-500"
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/auth/login`,
                      });
                    }}>
              Back to login
            </button>
          </p>
        </div>
        :
        <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">Register an account</h2>
          <input type="hidden" name="remember" defaultValue="true"/>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="relative">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Full name"
                value={this.state.name}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
              />
              {this.state.error === null || !("name" in this.state.error) ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      {this.state.error["name"]?.[0]}
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className="relative">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="text"
                autoComplete="email"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={this.props.email}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
              />
              {this.state.error === null || !("email" in this.state.error) ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      {this.state.error["email"]?.[0]}
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
                onBlur={() => {
                  let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
                  if (this.state.password !== this.state.confirmPassword && this.state.confirmPassword !== "") {
                    error['confirm'] = ["Passwords do not match"]
                    this.setState({error})
                  } else {
                    error['confirm'] = null
                    this.setState({error})
                  }
                }
                }
              />
              {this.state.error === null || !("password" in this.state.error) ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      {this.state.error["password"]?.[0]}
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className="relative">
              <label htmlFor="confirm-password" className="sr-only">
                Confirm password
              </label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Confirm password"
                value={this.state.confirmPassword}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
                onBlur={() => {
                  let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
                  if (this.state.password !== this.state.confirmPassword && this.state.password !== "") {
                    error['confirm'] = ["Passwords do not match"]
                    this.setState({error})
                  } else {
                    error['confirm'] = null
                    this.setState({error})
                  }
                }
                }
              />
              {this.state.error === null || !("confirm" in this.state.error) || this.state.error["confirm"] === null ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      Passwords do not match
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={(this.state.submitting === true ? "opacity-50 cursor-not-allowed" : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
              disabled={this.state.submitting}
            >
              Register
            </button>
          </div>
          <div>
            <p className="mt-2 text-center text-sm text-gray-600">
              <button className="font-medium text-purple-600 hover:text-purple-500"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.history.push({
                          pathname: `/auth/login`,
                        });
                      }}>
                I already have an account
              </button>
            </p>
          </div>
        </form>
    )
  }
}

export default withRouter(Register);