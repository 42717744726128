import ViewedDateIdeasListCard from "./IdeaListCard";
import React, {Component} from "react";
import IdeaViewsDistributionCard from "./IdeaViewsDistributionCard";
import IdeasCountCard from "./IdeasCountCard";
import IdeaTotalViewsCard from "./IdeaTotalViewsCard";
import IdeaCompletionDistributionCard from "./IdeaCompletionDistributionCard";

export default class IdeaStatsSection extends Component {

  state = {
    showSlider: false,
  };

  render() {
    return <>
      <div className="lg:col-span-3 col-span-2 text-center text-gray-600 font-medium mt-5 mb-3"
           style={{fontSize: 36}}>
        IDEA STATS
      </div>

      <IdeasCountCard/>

      <IdeaTotalViewsCard/>

      <ViewedDateIdeasListCard
        showSlider={this.state.showSlider}
      />

      <IdeaViewsDistributionCard
        showSlider={this.state.showSlider}
        handleShowSlider={() => this.setState({showSlider: !this.state.showSlider})}
      />

      <IdeaCompletionDistributionCard/>
    </>
  }
}