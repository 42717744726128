import {Component} from "react";
import inMemoryJwt from "../../inMemoryJwt";
import {postRequest} from "../../requestHandler";
import {withRouter} from "react-router-dom";

class Login extends Component {
  state = {
    password: "",
    rememberMe: false,
    error: null,
    submitting: false
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === "email") {
      this.props.handleEmailChange(value);
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitting: true});
    postRequest('/auth/login/admin', {
      email: this.props.email,
      password: this.state.password
    })
      .then((res) => {
        this.setState({submitting: false});
        inMemoryJwt.setTokens(this.state.rememberMe, res.data);
        this.props.handleLogin();
      })
      .catch((e) => {
        if (e.response === undefined) {
          this.setState({
            error: "There was an error connecting to the server."
          });
        } else {
          let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
          if (e.response.data?.['code'] === "VALIDATION_ERROR") {
            this.setState({
              error: e.response.data['invalid_data']
            });
          } else if (
            e.response.data?.['code'] === "EMAIL_NOT_VERIFIED"
            || e.response.data?.['code'] === "INCORRECT_CREDENTIALS"
            || e.response.data?.['code'] === "USER_NOT_ADMIN"
          ) {
            error = [e.response.data['message']];
            this.setState({error});
          } else {
            console.error(e.response.data);
          }
        }
        this.setState({submitting: false});
      });
  }

  render() {
    return (
      <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        <input type="hidden" name="remember" defaultValue="true"/>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="text"
              autoComplete="email"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              value={this.props.email}
              onChange={this.handleInputChange}
              disabled={this.state.submitting}
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleInputChange}
              disabled={this.state.submitting}
            />
          </div>
        </div>
        {this.state.error === null ? null :
          <p className="ml-2 text-left text-sm text-red-500">
            {this.state.error}
          </p>
        }

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="rememberMe"
              name="rememberMe"
              type="checkbox"
              className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              disabled={this.state.submitting}
              onChange={this.handleInputChange}
            />
            <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>

          <div className="text-sm">
            <button type="button" className="font-medium text-purple-600 hover:text-purple-500"
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/auth/forgot-password`,
                      });
                    }}>
              Forgot your password?
            </button>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className={(this.state.submitting === true ? "opacity-50 cursor-not-allowed" : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
            disabled={this.state.submitting}
          >
            Sign in
          </button>
        </div>
        <div>
          <p className="mt-2 text-center text-sm text-gray-600">
            <button className="font-medium text-purple-600 hover:text-purple-500"
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/auth/register`,
                      });
                    }}>
              I don't have an account
            </button>
          </p>
        </div>
      </form>
    )
  }
}

export default withRouter(Login);