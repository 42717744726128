import React, {Component} from "react";
import {LogoutIcon} from "@heroicons/react/outline";
import {SunIcon} from "@heroicons/react/solid";
import inMemoryJwt from "../inMemoryJwt";
import {withRouter} from "react-router-dom";
import {SpringReverse} from 'react-burgers';
import SmoothCollapse from "react-smooth-collapse";

class NavBar extends Component {
  state = {
    active: false,
  }

  handleSignOut = () => {
    this.props.history.push({
      pathname: `/auth/login`,
    });
    inMemoryJwt.eraseTokens();
    this.props.handleSignOut();
  }

  render() {
    return (
      <nav className="bg-white dark:bg-gray-800  shadow ">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex h-16">
            <div className="w-full justify-between flex items-center">
              <div className="flex justify-center items-center">
                <a className="flex justify-center items-center mr-4" href="/">
                  <SunIcon className="h-10 w-10 text-red-500 mx-auto justify-center" aria-hidden="true"/>
                  <h5 className="ml-1">Admin Panel</h5>
                </a>
                <div className="md:flex hidden">
                  <button
                    className={"justify-center items-center hover:text-gray-800 flex ml-5 px-2 py-1 rounded-md text-lg text-gray-"
                      + ((this.props.location.pathname === '/' || (this.props.location.pathname.includes('/idea/') && this.props.location.state?.fromStats !== true)) ? '800' : '300')}
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/`,
                      });
                    }}>
                    <p className="hidden xs:block">Ideas</p>
                  </button>
                  <button
                    className={"justify-center items-center hover:text-gray-800 flex ml-5 px-2 py-1 rounded-md text-lg text-gray-" + (this.props.location.pathname === '/stats' || this.props.location.state?.fromStats === true ? '800' : '300')}
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: `/stats`,
                      });
                    }}>
                    <p className="hidden xs:block">Stats</p>
                  </button>
                </div>
              </div>
              <div className="md:flex items-baseline space-x-4 hidden">
                <button
                  className="text-gray-500 justify-center items-center flex hover:text-gray-800 pl-3 py-2 rounded-md text-sm font-medium"
                  onClick={(e) => {
                    e.preventDefault()
                    this.handleSignOut();
                  }}>
                  <h5 className="hidden xs:block">Sign out</h5>
                  <LogoutIcon className="h-5 w-5 ml-2 mt-0.5" aria-hidden="true"/>
                </button>
              </div>
              <div className="flex items-baseline space-x-4 md:hidden mt-1">
                <SpringReverse
                  onClick={() => this.setState({active: !this.state.active})}
                  active={this.state.active}
                  lineHeight={3}
                  width={24}
                  lineSpacing={6}
                  padding='0px'
                  color={'#3d3d3d'}
                />
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
              </div>
            </div>
          </div>
          <SmoothCollapse expanded={this.state.active}>
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <button
                  className={"text-gray-300 hover:text-gray-800 block px-3 py-2 rounded-md text-base font-medium text-gray-"
                    + ((this.props.location.pathname === '/' || (this.props.location.pathname.includes('/idea/') && this.props.location.state?.fromStats !== true)) ? '800' : '300')}
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push({
                      pathname: `/`,
                    });
                  }}>
                  Ideas
                </button>
                <button
                  className={"hover:text-gray-800 block px-3 py-2 rounded-md text-base font-medium text-gray-"
                    + (this.props.location.pathname === '/stats' || this.props.location.state?.fromStats === true ? '800' : '300')}
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push({
                      pathname: `/stats`,
                    });
                  }}>
                  Stats
                </button>
                <button
                  className="text-gray-300 hover:text-gray-800 flex px-3 py-2 rounded-md text-base font-medium items-center"
                  onClick={this.handleSignOut}>
                  Sign out
                  <LogoutIcon className="h-5 w-5 ml-2 mt-0.5" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </SmoothCollapse>
        </div>
      </nav>
    )
  }
}

export default withRouter(NavBar);
