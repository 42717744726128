import {Component} from "react";
import {withRouter} from "react-router-dom";
import {HeartIcon} from "@heroicons/react/solid";

class VerificationComplete extends Component {
  render() {
    return (
      <div className="absolute inset-0">
        <div className="min-h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <HeartIcon className="h-20 w-20 text-red-500 mx-auto justify-center" aria-hidden="true"/>
              <h5 className="text-center text-gray-900">Admin Panel</h5>
            </div>
            <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 mb-5">Verification Complete!</h2>
            <h5 className="text-center text-gray-900 mb-10">Thanks for verifying your email, you can now log into your
              account.</h5>
            <p className="mt-2 text-center text-sm text-gray-600">
              <button
                className="font-medium text-purple-600 hover:text-purple-500"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: "/auth/login",
                  });
                }}
              >
                Go to login
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerificationComplete);