import {withRouter} from "react-router-dom";

const InvalidToken = (props) => (
  <div>
    <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 mb-5">{props.text}</h2>
    <p className="mt-12 text-center text-sm text-gray-600">
      <button className="font-medium text-purple-600 hover:text-purple-500"
              onClick={(e) => {
                e.preventDefault()
                props.history.push({
                  pathname: "/auth/login",
                });
              }}>
        Back to login
      </button>
    </p>
  </div>
)

export default withRouter(InvalidToken);