import React, {Component} from "react";
import _ from "lodash";
import Modal from "react-modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {withRouter} from "react-router-dom";
import {XIcon} from "@heroicons/react/outline";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(107, 114, 128, 0.4)',
    zIndex: 100
  },
  content: {
    borderRadius: "0.5rem",
    padding: 0,
    maxWidth: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

class MultiSelectFilter extends Component {
  state = {
    availableLabels: []
  }

  async componentDidMount() {
    let selectableLabels = this.props.history.location.state.availableLabels
      [this.props.id === "available_equipment" ? "required_equipment" : this.props.id];
    selectableLabels = selectableLabels.filter(x => x !== 'None' && x !== 'Any');
    this.setState({availableLabels: selectableLabels});
  }

  render() {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className="mt-5 cursor-pointer outline-none focus-visible:bg-gray-100 rounded">
              <span
                className="text-lg inline ">{this.props.title}</span>
            <span
              className={"text-sm ml-2 inline " + (this.props.enabled === false ? "hidden" : "text-gray-400")}>
                {this.props.enabled === false ? 0 : this.props.selectedLabels.length} of {this.state.availableLabels.length} selected
              </span>
            <hr/>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            {
              this.props.enableButton === true
                ?
                <div className="flex items-center ml-1 mt-2">
                  <input
                    id={`enable_${this.props.id}`}
                    name={`enable_${this.props.id}`}
                    type="checkbox"
                    checked={this.props.enabled === true}
                    className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                    onChange={this.props.handleEnableButton}
                  />
                  <label htmlFor={`enable_${this.props.id}`} className="ml-2 block text-sm text-gray-900">
                    Use {this.props.title.toLowerCase()} filter?
                  </label>
                </div>
                : null
            }
            <div className="mt-2">
              {
                this.state.availableLabels.map(label =>
                  <button
                    key={label}
                    className={"px-4 py-1.5 mx-1 my-0.5 text-center text-sm rounded-full border border-purple-400 disabled:border-purple-200 " + (this.props.selectedLabels.includes(label) ? "disabled:bg-purple-200 bg-purple-400 text-white" : "text-purple-400 disabled:text-purple-200")}
                    onClick={() => {
                      this.props.handleSelectedLabel(this.props.id, label)
                    }}
                    disabled={this.props.enabled === false}
                  >
                    {label}
                  </button>
                )
              }
            </div>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
}

class TableFilterModal extends Component {
  defaultState = {
    min_completion_rate: '',
    max_completion_rate: '',
    min_duration_hrs: '',
    max_duration_hrs: '',
    min_price: '',
    max_price: '',
    is_outdoors: '',
    categories: [],
    suitable_times: [],
    suitable_weathers: [],
    suitable_seasons: [],
    available_equipment: [],
    enable_available_equipment: false
  };

  state = {
    min_completion_rate: '',
    max_completion_rate: '',
    min_duration_hrs: '',
    max_duration_hrs: '',
    min_price: '',
    max_price: '',
    is_outdoors: '',
    categories: [],
    suitable_times: [],
    suitable_weathers: [],
    suitable_seasons: [],
    available_equipment: [],
    enable_available_equipment: false
  }

  componentDidMount() {
    let urlSearchParams = new URLSearchParams(this.props.location.search);

    urlSearchParams.delete('limit');
    urlSearchParams.delete('offset');

    const filterSettings = {
      min_completion_rate: '',
      max_completion_rate: '',
      min_duration_hrs: '',
      max_duration_hrs: '',
      min_price: '',
      max_price: '',
      is_outdoors: '',
      categories: [],
      suitable_times: [],
      suitable_weathers: [],
      suitable_seasons: [],
      available_equipment: [],
    };

    const keysToNotParse = ['query', 'sort_by'];
    for (let key of urlSearchParams.keys()) {
      if (!keysToNotParse.includes(key)) {
        filterSettings[key] = JSON.parse(urlSearchParams.get(key));
      }
    }

    this.setState({...filterSettings});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search || prevProps.open !== this.props.open) {
      let urlSearchParams = new URLSearchParams(this.props.location.search);

      urlSearchParams.delete('limit');
      urlSearchParams.delete('offset');

      const filterSettings = {
        min_completion_rate: '',
        max_completion_rate: '',
        min_duration_hrs: '',
        max_duration_hrs: '',
        min_price: '',
        max_price: '',
        is_outdoors: '',
        categories: [],
        suitable_times: [],
        suitable_weathers: [],
        suitable_seasons: [],
        available_equipment: [],
      };

      const keysToNotParse = ['query', 'sort_by'];
      for (let key of urlSearchParams.keys()) {
        if (!keysToNotParse.includes(key)) {
          filterSettings[key] = JSON.parse(urlSearchParams.get(key));
        }
      }

      this.setState({...filterSettings});
    }
  }

  handleInputChange = (e) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.type === 'number') {
      value = target.value === "" ? "" : parseInt(target.value);
      if (name !== "max_completion_rate" && name !== "min_completion_rate" && value > 99) {
        this.setState({
          [name]: 99
        });
        return;
      } else if (value > 100) {
        this.setState({
          [name]: 100
        });
        return;
      }
    }

    if (name === 'location') {
      value = target.value === "false" ? false : target.value === "true" ? true : '';
    }

    if (name === 'max_price' || name === 'min_price') {
      value = target.value === "" ? "" : parseInt(target.value);
    }

    this.setState({
      [name]: value
    });
  }

  handleSelectedLabel = (key, label) => {
    if (this.state[key].includes(label)) {
      this.setState({[key]: [...(this.state[key].filter(e => e !== label))]});
    } else {
      this.setState({[key]: [...this.state[key], label]});
    }
  }

  handleSubmitFilter = () => {
    if (Object.values(this.state ?? {}).every(x => x === '' || x?.length === 0)) {
      this.props.handleFilterSubmit(null);
    } else {
      this.props.handleFilterSubmit(this.state);
    }
  }

  handleClearFilter = () => {
    this.setState({
      min_completion_rate: '',
      max_completion_rate: '',
      min_duration_hrs: '',
      max_duration_hrs: '',
      min_price: '',
      max_price: '',
      is_outdoors: '',
      categories: [],
      suitable_times: [],
      suitable_weathers: [],
      suitable_seasons: [],
      available_equipment: [],
      enable_available_equipment: false,
      isEdited: true,
    })
  }

  stateIsDefault = () => {
    // Check that filter has been set back to default
    let defaultState = JSON.parse(JSON.stringify(this.defaultState));
    let state = JSON.parse(JSON.stringify(this.state));
    delete defaultState['isEdited']
    delete state['isEdited']
    return _.isEqual(state, defaultState);
  }

  render() {

    return (
      <Modal
        isOpen={this.props.open}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="overflow-y-hidden py-4 max-w-full" style={{width: "90vw"}}>
          <div className="flex items-center mb-6 justify-between">
            <h2 className="text-2xl font-medium mx-3">Filters</h2>
            {
              !Object.values(this.state ?? {}).every(x => x === '' || x?.length === 0)
                ?
                <button
                  className={"flex justify-center items-center max-w-min my-1 ml-2 mr-6 whitespace-nowrap transition text-sm ease-in duration-100"}
                  onClick={this.stateIsDefault() ? this.props.onRequestClose : this.handleClearFilter}
                >
                  <XIcon
                    className="h-4 w-4 mr-1"
                  />
                  {this.stateIsDefault() ? "Close" : "Clear"}
                </button>
                :
                null
            }
          </div>
          <div style={{maxHeight: '80vh'}} className="overflow-y-auto px-3">
            <label className="flex items-center space-x-3">
              <span className="text-gray-700 dark:text-white font-normal flex-nowrap">
                  Min completion rate
                </span>
              <input
                type="number"
                name="min_completion_rate"
                className="form-tick pl-1 appearance-none bg-white border w-14 border-gray-300 rounded-md checked:bg-purple-400 checked:border-transparent focus:outline-none"
                value={this.state.min_completion_rate}
                onChange={this.handleInputChange}
              />
            </label>
            <label className="flex items-center space-x-3 mt-3">
              <span className="text-gray-700 dark:text-white font-normal flex-nowrap">
                  Max completion rate
                </span>
              <input
                type="number"
                name="max_completion_rate"
                className="form-tick pl-1 appearance-none bg-white border w-14 border-gray-300 rounded-md checked:bg-purple-400 checked:border-transparent focus:outline-none"
                value={this.state.max_completion_rate}
                onChange={this.handleInputChange}
              />
            </label>
            <label className="flex items-center space-x-3 mt-3">
              <span className="text-gray-700 dark:text-white font-normal flex-nowrap">
                  Min duration (hrs)
                </span>
              <input
                type="number"
                name="min_duration_hrs"
                className="form-tick pl-1 appearance-none bg-white border w-12 border-gray-300 rounded-md checked:bg-purple-400 checked:border-transparent focus:outline-none"
                maxLength={2}
                value={this.state.min_duration_hrs}
                onChange={this.handleInputChange}
              />
            </label>
            <label className="flex items-center space-x-3 mt-3">
              <span className="text-gray-700 dark:text-white font-normal flex-nowrap">
                  Max duration (hrs)
                </span>
              <input
                type="number"
                name="max_duration_hrs"
                className="form-tick pl-1 appearance-none bg-white border w-12 border-gray-300 rounded-md checked:bg-purple-400 checked:border-transparent focus:outline-none"
                maxLength={2}
                value={this.state.max_duration_hrs}
                onChange={this.handleInputChange}
              />
            </label>
            <label className="flex items-center space-x-3 w-max mt-3">
              <span className="text-gray-700 whitespace-nowrap">Min Price</span>
              <select value={this.state.min_price} onChange={this.handleInputChange} name="min_price"
                      className="form-select block w-full border-gray-300 border rounded-lg">
                <option value={''}>No min</option>
                <option value={0}>Free</option>
                <option value={1}>£</option>
                <option value={2}>££</option>
                <option value={3}>£££</option>
                <option value={4}>££££</option>
              </select>
            </label>
            <label className="flex items-center space-x-3 w-max mt-3">
              <span className="text-gray-700 whitespace-nowrap">Max Price</span>
              <select value={this.state.max_price} onChange={this.handleInputChange} name="max_price"
                      className="form-select block w-full border-gray-300 border rounded-lg">
                <option value={''}>No max</option>
                <option value={0}>Free</option>
                <option value={1}>£</option>
                <option value={2}>££</option>
                <option value={3}>£££</option>
                <option value={4}>££££</option>
              </select>
            </label>
            <label className="flex items-center space-x-3 w-max mt-3">
              <span className="text-gray-700">Location</span>
              <select value={this.state.is_outdoors} onChange={this.handleInputChange} name="is_outdoors"
                      className="form-select block w-full border-gray-300 border rounded-lg">
                <option value={''}>No preference</option>
                <option value={false}>Indoors</option>
                <option value={true}>Outdoors</option>
              </select>
            </label>

            <Accordion allowZeroExpanded>
              <MultiSelectFilter
                id="categories"
                title="Categories"
                history={this.props.history}
                selectedLabels={this.state.categories}
                handleSelectedLabel={this.handleSelectedLabel}
              />
              <MultiSelectFilter
                id="suitable_times"
                title="Suitable Times"
                history={this.props.history}
                selectedLabels={this.state.suitable_times}
                handleSelectedLabel={this.handleSelectedLabel}
              />
              <MultiSelectFilter
                id="suitable_weathers"
                title="Suitable Weather"
                history={this.props.history}
                selectedLabels={this.state.suitable_weathers}
                handleSelectedLabel={this.handleSelectedLabel}
              />
              <MultiSelectFilter
                id="suitable_seasons"
                title="Suitable Seasons"
                history={this.props.history}
                selectedLabels={this.state.suitable_seasons}
                handleSelectedLabel={this.handleSelectedLabel}
              />
              <MultiSelectFilter
                id="available_equipment"
                title="Available Equipment"
                history={this.props.history}
                selectedLabels={this.state.available_equipment}
                handleSelectedLabel={this.handleSelectedLabel}
                handleEnableButton={this.handleInputChange}
                enableButton
                enabled={this.state.enable_available_equipment}
              />
            </Accordion>

            <button
              className="py-2 px-8 flex items-center mx-auto mt-6 mb-2 bg-purple-400 hover:bg-purple-600 focus:ring-purple-400 focus:ring-offset-purple-200 text-white transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              onClick={this.handleSubmitFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withRouter(TableFilterModal);