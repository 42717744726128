import {Component} from "react";
import {HeartIcon} from "@heroicons/react/solid";
import {postRequest} from "../../requestHandler";
import jwt_decode from "jwt-decode";
import {Route, Switch, withRouter} from "react-router-dom";
import InvalidToken from "./InvalidToken";
import NoRoute from "./NoRoute";

class PasswordReset extends Component {
  render() {
    return (
      <div className="absolute inset-0">
        <div className="min-h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <HeartIcon className="h-20 w-20 text-red-500 mx-auto justify-center" aria-hidden="true"/>
              <h5 className="text-center text-gray-900">Admin Panel</h5>
            </div>
            <Switch>
              <Route exact path={`${this.props.match.path}/`}>
                <PasswordResetForm/>
              </Route>

              <Route exact path={`${this.props.match.path}/token-invalid`}>
                <InvalidToken text="Password reset token is invalid"/>
              </Route>

              <Route path={`${this.props.match.path}/token-used`}>
                <InvalidToken text="Password reset token has already been used"/>
              </Route>

              <Route exact path={`${this.props.match.path}/token-expired`}>
                <InvalidToken text="Password reset token has expired"/>
              </Route>

              <Route path="*">
                <NoRoute/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    )

  }
}

const PasswordResetForm = withRouter(class PasswordResetForm extends Component {
  state = {
    token: "",
    tokenExpired: null,
    email: "",
    new_password: "",
    confirmPassword: "",
    error: null,
    submitting: false,
    success: false
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const token = urlSearchParams.get("token");
    if (token !== null) {
      const decodedToken = jwt_decode(token);
      const dateNow = new Date();
      const tokenExpired = decodedToken.exp <= (dateNow.getTime() / 1000);
      this.setState({token, email: decodedToken.sub, tokenExpired});
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({submitting: true});

    const config = {headers: {Authorization: `Bearer ${this.state.token}`}};
    const body = {
      new_password: this.state.new_password
    };

    postRequest('/auth/update-password', body, config)
      .then(() => {
        this.setState({success: true, submitting: false});
      })
      .catch((e) => {
        if (e.response.data?.['code'] === "VALIDATION_ERROR" || e.response.data?.['code'] === "INVALID_PASSWORD") {
          this.setState({
            error: e.response.data['invalid_data']
          });
        } else {
          console.error(e.response.data);
        }
        this.setState({submitting: false});
      });
  }

  render() {
    if (this.state.success) {
      return (
        <div>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 mb-5">Password reset
            success!</h2>
          <h5 className="text-center text-gray-900 mb-10">Your password has been successfully reset.</h5>
          <p className="mt-2 text-center text-sm text-gray-600">
            <button className="font-medium text-purple-600 hover:text-purple-500"
                    onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: "/auth/login",
                      });
                    }}>
              Back to login
            </button>
          </p>
        </div>
      )
    } else {
      return (
        <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
          <p className="mt-2 text-center text-gray-900">Enter the new password for your account registered
            with the email '{this.state.email}'</p>
          <input type="hidden" name="remember" defaultValue="true"/>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="relative">
              <label htmlFor="new_password" className="sr-only">
                New Password
              </label>
              <input
                id="new_password"
                name="new_password"
                type="password"
                className="appearance-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="New Password"
                value={this.state.new_password}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
                onBlur={() => {
                  let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
                  if (this.state.new_password !== this.state.confirmPassword && this.state.confirmPassword !== "") {
                    error['confirm'] = ["Passwords do not match"]
                    this.setState({error})
                  } else {
                    error['confirm'] = null
                    this.setState({error})
                  }
                }
                }
              />
              {this.state.error === null || !("new_password" in this.state.error) ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      {this.state.error["new_password"]?.[0]}
                    </p>
                  </div>
                </div>
              }
            </div>
            <div className="relative">
              <label htmlFor="confirm-password" className="sr-only">
                Confirm password
              </label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                placeholder="Confirm password"
                value={this.state.confirmPassword}
                onChange={this.handleInputChange}
                disabled={this.state.submitting}
                onBlur={() => {
                  let error = this.state.error === null ? {} : JSON.parse(JSON.stringify(this.state.error));
                  if (this.state.new_password !== this.state.confirmPassword && this.state.new_password !== "") {
                    error['confirm'] = ["Passwords do not match"]
                    this.setState({error})
                  } else {
                    error['confirm'] = null
                    this.setState({error})
                  }
                }
                }
              />
              {this.state.error === null || !("confirm" in this.state.error) || this.state.error["confirm"] === null ? null :
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                       className="absolute text-red-500 right-2 bottom-3" viewBox="0 0 1792 1792">
                    <path
                      d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                  <div className="absolute w-80 -right-80 top-2">
                    <p className="ml-2 text-left text-sm text-red-500">
                      Passwords do not match
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={(this.state.submitting === true ? "opacity-50 cursor-not-allowed" : "") + "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
              disabled={this.state.submitting}
            >
              Set Password
            </button>
          </div>
        </form>
      )
    }
  }
})

export default withRouter(PasswordReset);