import React, {Component} from "react";
import {getTrackBackground, Range} from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 300;

export default class CustomSlider extends Component {
  render() {
    return <div
      className="px-20 -top-2.5 relative"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}
    >
      <Range
        draggableTrack
        values={this.props.values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={this.props.onChange}
        renderTrack={({props, children}) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: this.props.values,
                  colors: ['#ccc', '#e11d48', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({props, isDragged}) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '24px',
              width: '24px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                height: '10px',
                width: '2px',
                backgroundColor: isDragged ? '#e11d48' : '#CCC'
              }}
            />
          </div>
        )}
      />
    </div>;
  }
}