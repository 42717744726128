import React, {Component} from "react";
import TextareaAutosize from 'react-textarea-autosize';
import MultiSelectField from "./MultiSelectField";
import NumberField, {PriceField} from "./NumberField";
import BoolField from "./BoolField";
import TextField from "./TextField";
import CreatedAndUpdatedInfo from "./CreatedAndUpdatedInfo";
import {UploadIcon} from "@heroicons/react/outline";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest, postRequest, putRequest} from "../../requestHandler";
import PlaceSuggestionConfigTest from "./PlaceSuggestionConfigTest";
import {withRouter} from "react-router-dom";


class IdeaCardPlaceholder extends Component {
  render() {
    return <div className="container mt-5 mx-auto px-4 py-7 max-w-5xl bg-white shadow rounded-2xl mb-3">
      <div className="bg-gray-400 animate-pulse h-7 rounded-lg mt-1 mb-8"
           style={{maxWidth: "500px", width: "70%"}}/>

      <div
        className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg"}>
        <h4 className="mb-1 text-gray-400 font-medium text-sm">Description</h4>
        <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "700px", width: "90%"}}/>
        <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{maxWidth: "650px", width: "85%"}}/>
        <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{maxWidth: "550px", width: "70%"}}/>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-6 gap-4 mt-4">
        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-2 xs:col-span-1"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Duration (hrs)</h4>
          <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "100px", width: "30%"}}/>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-2 xs:col-span-1"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Is outdoors</h4>
          <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "200px", width: "60%"}}/>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-2 xs:col-span-1"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Price</h4>
          <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "200px", width: "70%"}}/>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 md:col-span-3"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Categories</h4>
          <div className="flex">
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "80px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2 mx-2" style={{width: "55px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "105px"}}/>
          </div>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-4 md:grid-cols-8 gap-4">

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 xs:col-span-2"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Suitable Times</h4>
          <div className="flex flex-wrap">
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "45px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2 mx-2" style={{width: "90px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "115px"}}/>
          </div>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 xs:col-span-2"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Suitable Weathers</h4>
          <div className="flex flex-wrap">
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "125px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2 mx-2" style={{width: "65px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "80px"}}/>
          </div>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 xs:col-span-2"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Suitable Seasons</h4>
          <div className="flex flex-wrap">
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "55px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2 mx-2" style={{width: "85px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "70px"}}/>
          </div>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 xs:col-span-2"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Required Equipment</h4>
          <div className="flex flex-wrap">
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "80px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2 mx-2" style={{width: "55px"}}/>
            <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-2" style={{width: "105px"}}/>
          </div>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-4 gap-4">

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-2 sm:col-span-1"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Suggest Places?</h4>
          <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "200px", width: "60%"}}/>
        </div>

        <div className={"pt-2 pb-3 px-3 bg-gray-100 shadow-inner rounded-lg col-span-4 sm:col-span-3"}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">Suggested places search
            query</h4>
          <div className="bg-gray-300 animate-pulse h-4 rounded-lg mt-3" style={{maxWidth: "400px", width: "60%"}}/>
        </div>
      </div>
    </div>;
  }
}

class IdeaCard extends Component {
  defaultLocation;

  state = {
    errors: {},
    zoom: 3,
    suggested_places: {
      nearby_results: [],
      extended_results: []
    },
    availableFoursquareCategoryLabels: [],
    flattenedAvailableFoursquareCategoryLabels: [],
  }

  container = React.createRef();
  export
  default

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};

    switch (this.props.mode) {

      case "create":
        this.setState({
          idea: {
            title: "",
            description: "",
            duration_hrs: "",
            is_outdoors: "",
            price: 0,
            suitable_times: [],
            categories: [],
            suitable_weathers: [],
            suitable_seasons: [],
            required_equipment: [],
            suggest_places: false,
            suggested_places_query: ""
          },
          selected: "",
        })
        break;

      default:
        if (this.props.location.state === undefined || this.props.location.state.fromStats === true) {
          await getRequest(
            `/ideas/${this.props.id}`, config)
            .then(res => {
              let suggested_places_query = res.data.suggested_places_query
              if (suggested_places_query === null) {
                suggested_places_query = ""
              }
              this.setState({idea: {...res.data, suggested_places_query}});
            })
            .catch(err => {
              console.error(err.response?.data || err);
            })
        } else {
          let suggested_places_query = this.props.location.state.idea.suggested_places_query
          if (suggested_places_query === null) {
            suggested_places_query = ""
          }
          this.setState({idea: {...this.props.location.state.idea, suggested_places_query}});
        }
        break;

    }

    function recursivelyFlattenFoursquareApiCategories(categories, flattenedCategories) {
      categories.forEach(e => {
        flattenedCategories[e.id] = e.name;
        if (e.categories.length > 0) {
          recursivelyFlattenFoursquareApiCategories(e.categories, flattenedCategories);
        }
      });
    }

    await getRequest(
      `/ideas/place_suggestion/categories`, config)
      .then(res => {
        const categories = res.data.response['categories'];
        let flattenedCategories = {};
        recursivelyFlattenFoursquareApiCategories(categories, flattenedCategories);
        this.setState({
          availableFoursquareCategoryLabels: categories,
          flattenedAvailableFoursquareCategoryLabels: flattenedCategories
        });
      })
      .catch(err => {
        console.error(err.response?.data || err);
      })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !event.target.matches('#dropdown, #dropdown *')
    ) {
      this.setState({
        selected: "",
      });
    }
  };

  handleInputChange = (e) => {
    if (e.target.name === "suggested_places_query") {
      this.setState({errors: {...(delete this.state.errors.suggested_places_query)}})
    }
    if (typeof e.target.value === "string") {
      e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    }

    let idea = JSON.parse(JSON.stringify(this.state.idea));
    idea[e.target.name] = e.target.value;
    this.setState({idea});
  }

  handleBoolClick = (e) => {
    const value = e.target.innerText === "?" ? true : e.target.innerText !== "True";
    let idea = JSON.parse(JSON.stringify(this.state.idea));
    idea[e.target.id] = value;
    this.setState({idea});
  }

  handlePriceClick = () => {
    const idea = JSON.parse(JSON.stringify(this.state.idea));
    if (idea.price >= 4 || idea.price < 0) {
      idea.price = 0;
    } else {
      idea.price++;
    }
    this.setState({idea});
  }

  handlePriceKeyPress = (e) => {
    this.setState({idea: {...this.state.idea, price: e.key}});
  }

  handleDropdown = (e) => {
    this.setState({selected: e});
  }

  handleDropdownClick = (e) => {
    let idea = JSON.parse(JSON.stringify(this.state.idea));
    const labelType = e.target.parentElement.parentElement.parentElement.parentElement.id;
    const labelValue = e.target.innerText;

    if (labelValue === "Any" || labelValue === "None") {
      idea[labelType] = [labelValue]
    } else {
      idea[labelType] = idea[labelType].filter(e => e !== (labelType !== "required_equipment" ? "Any" : "None"))
      idea[labelType].push(labelValue);
    }

    this.setState({idea})
  }

  handleNestedDropdownClick = (e, labelsToRemove) => {

    let idea = JSON.parse(JSON.stringify(this.state.idea));
    let labelType = e.target.parentElement.parentElement.parentElement.id;
    if (labelType === "dropdown") {
      labelType = e.target.parentElement.id
    }
    const labelValue = e.target.innerText;

    idea[labelType] = idea[labelType].filter(e => !labelsToRemove.includes(e))
    idea[labelType].push(labelValue);

    this.setState({idea})
  }

  handlePromptClick = e => {
    let newQuery = this.state.idea.suggested_places_query;

    if (newQuery === "") {
      newQuery = e.target.innerText;
    } else {
      newQuery += " OR " + e.target.innerText;
    }

    this.setState({
      idea: {
        ...this.state.idea,
        suggested_places_query: newQuery
      }
    })
  }

  handleLabelRemove = (e) => {
    let idea = JSON.parse(JSON.stringify(this.state.idea));

    const targetElement = e.target.closest("#label-container").children[1];

    const labelType = targetElement.firstElementChild.id;
    let labelValue;
    if (labelType === "suggested_places_categories") {
      labelValue = targetElement.id;
    } else {
      labelValue = targetElement.innerText.trim();
    }
    idea[labelType] = idea[labelType].filter(e => e !== labelValue);
    this.setState({idea});
  }

  handleSubmitIdea = async () => {
    const body = this.state.idea;
    if (body.suggest_places === false) {
      body.suggested_places_query = null;
      this.setState({idea: {...this.state.idea, suggested_places_query: ""}});
    }

    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};

    if (body.suggest_places === true && body.suggested_places_query === "") {
      this.setState({errors: {suggested_places_query: ["Cannot be empty if suggest places is true"]}})
    } else {
      if (this.props.mode === "create") {
        postRequest(
          `/ideas/create`, body, config)
          .then(() => {
            this.setState({errors: {}});
            let state = JSON.parse(JSON.stringify(this.props.location.state.fromState));
            delete state['ideas'];

            this.props.history.push({
              pathname: "/",
              state
            });
          })
          .catch(err => {
            if (err.response.data?.code === "VALIDATION_ERROR") {
              this.setState({errors: err.response.data.invalid_data})
            } else if (err.response.data?.code === "INVALID_ENTRY") {
              this.setState({errors: {...this.state.errors, title: ["Idea already exists with this title"]}});
            }
            console.error(err.response?.data || err);
          })
      } else if (this.props.mode === "edit") {
        putRequest(
          `/ideas/update/${this.props.id}`, body, config)
          .then(() => {
            this.setState({errors: {}});
            this.props.history.goBack();
            this.props.onSubmitEdit();
          })
          .catch(err => {
            if (err.response.data?.code === "VALIDATION_ERROR") {
              this.setState({errors: err.response.data.invalid_data});
            } else if (err.response.data?.code === "INVALID_ENTRY") {
              this.setState({errors: {...this.state.errors, title: "Idea already exists with this title"}});
            }
            console.error(err.response?.data || err);
          })
      }
    }
  }

  handleSubmitSearchPlaces = (pos, radius) => {
    this.setState({
      suggested_places: {
        nearby_results: [],
        extended_results: []
      }
    }, async () => {
      let urlSearchParams = new URLSearchParams({
        location: pos.lat + "," + pos.lng,
        radius,
        query: this.state.idea.suggested_places_query
      });

      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      await getRequest(
        `/ideas/place_suggestion?` + urlSearchParams.toString(), config)
        .then(res => {
          const suggested_places = {
            nearby_results: res.data.nearby_results,
            extended_results: res.data.extended_results
          };
          this.setState({suggested_places})
        })
        .catch(err => {
          console.error(err.response?.data || err);
        })
    });
  }

  render() {
    return (
      this.state?.idea === undefined
        ?
        <IdeaCardPlaceholder/>
        :
        <div ref={this.container}
             className="container mt-5 mx-auto px-4 py-7 max-w-5xl bg-white shadow rounded-2xl mb-3">
          <div className="grid md:grid-cols-12 gap-x-4">
            <>
              {
                this.props.mode === "view"
                  ?
                  <h2
                    className={"text-3xl font-medium mb-2 " + (this.props.mode === "view" ? "col-span-9" : "col-span-12")}>{this.state.idea.title}
                  </h2>
                  :
                  <div className={"mb-2 " + (this.props.mode === "view" ? "col-span-9" : "col-span-12")}>
                    <TextareaAutosize
                      className={"text-3xl w-full font-medium bg-transparent focus:outline-none hover:bg-gray-100 transition ease-in duration-100 rounded resize-none placeholder-black focus:placeholder-transparent "}
                      maxRows={2}
                      placeholder="New Date Idea"
                      name="title"
                      value={this.state.idea.title}
                      onChange={this.handleInputChange}
                    />
                    <p className="text-red-700 text-xs relative -top-1.5">{this.state.errors['title']?.[0]}</p>
                  </div>
              }
            </>
            <CreatedAndUpdatedInfo idea={this.state.idea} mode={this.props.mode}/>
          </div>

          <TextField
            title="Description"
            name="description"
            multiline
            placeholder="Enter a description..."
            value={this.state.idea.description}
            mode={this.props.mode}
            idea={this.state.idea}
            error={this.state.errors['description']?.[0]}
            onChange={this.handleInputChange}
          />

          <div className="grid grid-cols-4 md:grid-cols-6 gap-4 mt-4">
            <NumberField
              className="col-span-2 xs:col-span-1"
              title="Duration (hrs)"
              name="duration_hrs"
              mode={this.props.mode}
              value={this.state.idea.duration_hrs}
              error={this.state.errors['duration_hrs']?.[0]}
              onChange={this.handleInputChange}
            />

            <BoolField
              className="col-span-2 xs:col-span-1"
              title="Is outdoors"
              id="is_outdoors"
              value={this.state.idea.is_outdoors}
              mode={this.props.mode}
              error={this.state.errors['is_outdoors']?.[0]}
              onClick={this.handleBoolClick}
            />

            <PriceField
              className="col-span-2 xs:col-span-1"
              title="Price"
              name="price"
              mode={this.props.mode}
              value={this.state.idea.price}
              error={this.state.errors['price']?.[0]}
              onClick={this.handlePriceClick}
              onKeyPress={this.handlePriceKeyPress}
            />

            <MultiSelectField
              className="col-span-4 md:col-span-3"
              title="Categories"
              id="categories"
              mode={this.props.mode}
              values={this.state.idea.categories}
              error={this.state.errors['categories']?.[0]}
              handleDropdown={this.handleDropdown}
              handleDropdownClick={this.handleDropdownClick}
              handleLabelRemove={this.handleLabelRemove}
              selected={this.state.selected}
            />
          </div>

          <div className="mt-8 grid grid-cols-4 md:grid-cols-8 gap-4">
            <MultiSelectField
              className="col-span-4 xs:col-span-2"
              title="Suitable Times"
              id="suitable_times"
              labelColour="bg-gray-400"
              mode={this.props.mode}
              values={this.state.idea.suitable_times}
              error={this.state.errors['suitable_times']?.[0]}
              handleDropdown={this.handleDropdown}
              handleDropdownClick={this.handleDropdownClick}
              handleLabelRemove={this.handleLabelRemove}
              selected={this.state.selected}
            />

            <MultiSelectField
              className="col-span-4 xs:col-span-2"
              title="Suitable Weather"
              id="suitable_weathers"
              labelColour="bg-gray-400"
              mode={this.props.mode}
              values={this.state.idea.suitable_weathers}
              error={this.state.errors['suitable_weathers']?.[0]}
              handleDropdown={this.handleDropdown}
              handleDropdownClick={this.handleDropdownClick}
              handleLabelRemove={this.handleLabelRemove}
              selected={this.state.selected}
            />

            <MultiSelectField
              className="col-span-4 xs:col-span-2"
              title="Suitable Seasons"
              id="suitable_seasons"
              labelColour="bg-gray-400"
              mode={this.props.mode}
              values={this.state.idea.suitable_seasons}
              error={this.state.errors['suitable_seasons']?.[0]}
              handleDropdown={this.handleDropdown}
              handleDropdownClick={this.handleDropdownClick}
              handleLabelRemove={this.handleLabelRemove}
              selected={this.state.selected}
            />

            <MultiSelectField
              className="col-span-4 xs:col-span-2"
              title="Required Equipment"
              id="required_equipment"
              labelColour="bg-gray-400"
              mode={this.props.mode}
              values={this.state.idea.required_equipment}
              error={this.state.errors['required_equipment']?.[0]}
              handleDropdown={this.handleDropdown}
              handleDropdownClick={this.handleDropdownClick}
              handleLabelRemove={this.handleLabelRemove}
              selected={this.state.selected}
            />
          </div>

          <div className="mt-8 grid grid-cols-4 gap-4">
            <BoolField
              className="col-span-2 sm:col-span-1"
              title="Suggest Places?"
              id="suggest_places"
              value={this.state.idea.suggest_places}
              error={this.state.errors['suggest_places']?.[0]}
              mode={this.props.mode}
              onClick={this.handleBoolClick}
            />

            <TextField
              title="Suggested places search query"
              className="col-span-4 sm:col-span-3"
              name="suggested_places_query"
              placeholder="Use OR to search for multiple things"
              disabled={!this.state.idea.suggest_places}
              value={this.state.idea.suggested_places_query}
              error={this.state.errors['suggested_places_query']?.[0]}
              mode={this.props.mode}
              idea={this.state.idea}
              onChange={this.handleInputChange}
              prompts
              promptValues={this.state.availableFoursquareCategoryLabels}
              flattenedPromptValues={this.state.flattenedAvailableFoursquareCategoryLabels}
              handleDropdown={this.handleDropdown}
              handlePromptClick={this.handlePromptClick}
              selected={this.state.selected}
            />
          </div>


          {
            this.props.mode !== "view"
              ?
              <>
                <div className={this.state.idea.suggest_places ? "" : "hidden"}>
                  <PlaceSuggestionConfigTest
                    disabled={!this.state.idea.suggest_places}
                    handleSubmitSearchPlaces={this.handleSubmitSearchPlaces}
                    suggested_places={this.state.suggested_places}
                    suggested_places_query={this.state.idea.suggested_places_query}
                  />
                </div>

                <button
                  type="button"
                  className="py-2 px-8 flex items-center mx-auto mt-12 bg-purple-400 hover:bg-purple-600 focus:ring-purple-400 focus:ring-offset-purple-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                  onClick={this.handleSubmitIdea}
                >
                  <UploadIcon className="h-5 w-5 mr-1.5 text-white"/>
                  Save
                </button>
              </>
              :
              null
          }
        </div>
    );
  }
}

export default withRouter(IdeaCard);