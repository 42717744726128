import {Component} from "react";
import {withRouter} from "react-router-dom";
import Highlighter from "react-highlight-words";


class TextCell extends Component {

  CustomHighlight = ({children}) => (
    <span className="bg-yellow-200 rounded">{children}</span>
  );

  render() {
    return <td className="px-5 py-5 border-b border-gray-200 text-sm">
      <p
        className="text-gray-900 whitespace-no-wrap whitespace-pre-line line-clamp-4 rounded">
        <span className="bg-transparent">
          <Highlighter
            searchWords={this.props.highlightText.split(' ')}
            autoEscape={true}
            highlightTag={this.CustomHighlight}
            textToHighlight={this.props.value}
          />
        </span>
      </p>
    </td>;
  }
}

// class BoolCell extends Component {
//   render() {
//     return <td className="px-5 py-5 border-b border-gray-200 text-sm">
//       {
//         this.props.value ?
//           <CheckIcon className="h-6 w-6 text-green-500 mx-auto justify-center"/>
//           :
//           <XIcon className="h-6 w-6 text-red-500 mx-auto justify-center"/>
//       }
//     </td>;
//   }
// }

class Label extends Component {
  colours = ["indigo", "blue", "red", "yellow", "purple", "green", "teal", "lime", "orange", "fuchsia"];

  getLabelColour = (value) => {
    let unicode_sum = 0;
    Array.from(value).forEach(letter => {
      unicode_sum += letter.charCodeAt(0);
    })

    const index = unicode_sum % this.colours.length;

    return `bg-` + (this.props.faded ? `gray-300` : `${this.colours[index]}-300`);
  }

  render() {
    return (
      <span
        className="relative m-0.5 inline-block px-3 py-1 font-semibold text-gray-600 leading-tight">
            <span aria-hidden="true"
                  className={"absolute inset-0 opacity-50 rounded-full " + this.getLabelColour(this.props.value)}>
            </span>
            <span className="relative">
                {this.props.value}
            </span>
        </span>
    );
  }
}

class LabelCell extends Component {
  render() {
    return (
      <td className="px-5 py-5 border-b border-gray-200 text-sm">
        {this.props.values.map(val => {
          const faded = this.props.filteredValues === null ? false : !this.props.filteredValues?.includes(val);
          return <Label key={val} value={val} faded={faded}/>
        })}
      </td>
    );
  }
}

class TableRow extends Component {

  onClickEvent = () => {
    return this.props.history.push({
      pathname: `/idea/${this.props.idea.id}`,
      state: {
        idea: this.props.idea,
        mode: "view",
        fromState: this.props.location.state,
        fromSearch: this.props.location.search
      }
    });
  }

  render() {
    let urlSearchParams = new URLSearchParams(this.props.location.search);
    return (
      <tr className="bg-white cursor-pointer hover:bg-gray-100" onClick={this.onClickEvent}>
        <TextCell value={this.props.idea.title} highlightText={this.props.highlightText}/>
        <TextCell value={this.props.idea.description} highlightText={this.props.highlightText}/>
        <LabelCell values={this.props.idea.categories}
                   filteredValues={urlSearchParams.get('categories') ?? null}/>
      </tr>
    );
  }
}

class TableRowPlaceholder extends Component {
  render() {
    return (
      <tr className="bg-white">
        <td className="px-5 py-2">
          <div className="bg-gray-200 md:w-32 animate-pulse h-3 rounded-2xl mt-1"/>
          <div className="bg-gray-200 md:w-20 animate-pulse h-3 rounded-2xl mt-1"/>
        </td>
        <td className="px-5 py-2">
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl mt-1"/>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl mt-1"/>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl mt-1"/>
          <div className="bg-gray-200 w-1/4 animate-pulse h-3 rounded-2xl mt-1"/>
        </td>
        <td className="px-5 py-2 flex flex-wrap">
          <div className="bg-gray-200 w-16 xs:w-1/3 animate-pulse h-5 rounded-2xl m-0.5"/>
          <div className="bg-gray-200 w-1/2 animate-pulse h-5 rounded-2xl m-0.5"/>
          <div className="bg-gray-200 w-12 xs:w-1/4 animate-pulse h-5 rounded-2xl m-0.5"/>
          <div className="bg-gray-200 w-1/3 animate-pulse h-5 rounded-2xl m-0.5"/>
          <div className="bg-gray-200 w-1/2 animate-pulse h-5 rounded-2xl m-0.5"/>
        </td>
      </tr>
    );
  }
}

export default withRouter(TableRow);
export {TableRowPlaceholder}