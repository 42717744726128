import {Component} from "react";
import MyMapComponent from "./CustomMap";
import NumberField from "./NumberField";
import {ChevronRightIcon, SearchIcon} from "@heroicons/react/solid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import RangeField from "./RangeField";
import Columns from "react-columns";

const queries = [{
  columns: 2,
  query: 'min-width: 640px'
}, {
  columns: 3,
  query: 'min-width: 768px'
}];

class SuggestedPlacesList extends Component {
  render() {
    return <Columns queries={queries}>
      {
        this.props.suggestedPlaces.map(place =>
          <div className="bg-gray-100 flex shadow-inner rounded-lg px-3 py-2 m-3" key={place.place_id}>
            <div>
              <a
                href={
                  "https://www.google.com/maps/search/?api=1&query="
                  + encodeURIComponent(place.name)
                  + "+"
                  + encodeURIComponent(place.formatted_address)
                }
                target="_blank"
                rel="noreferrer"
              >
                <p className="font-bold text-lg hover:underline">
                  {place.name}
                </p>
              </a>

              <p className="font-bold text-sm text-gray-500">
                {
                  place.types
                    .filter(type => type !== "meal_takeaway" && type !== "meal_delivery").slice(0, 1)
                    .map(type => type.charAt(0).toUpperCase() + type.replaceAll("_", " ").slice(1))
                }
              </p>
              <p
                className="font-bold text-sm text-gray-500">Rating: {place.rating} ({place.user_ratings_total})</p>
              <p className="font-bold text-sm text-gray-500">
                Distance: {
                this.props.markerPos !== null
                  ? place.distance + "m"
                  : "N/A"
              }
              </p>
              <p className="text-sm text-gray-400">{place.formatted_address}</p>
            </div>
          </div>
        )
      }
    </Columns>;
  }
}

export default class PlaceSuggestionConfigTest extends Component {
  state = {
    markerPos: null,
    searchRadius: 5000,
    open: false,
    mapLoaded: false,
    viewResults: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.open && this.state.open && !prevState.mapLoaded) {
      this.setState({mapLoaded: true});
    }
  }

  updateMarkerPos = (e) => {
    const latLng = e.latLng.toJSON();
    let markerPos = {
      lat: parseFloat(latLng.lat.toLocaleString(
        undefined, // leave undefined to use the visitor's browser
        // locale or a string like 'en-US' to override it.
        {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6
        }
      )),
      lng: parseFloat(latLng.lng.toLocaleString(
        undefined, // leave undefined to use the visitor's browser
        // locale or a string like 'en-US' to override it.
        {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6
        }
      ))
    };
    this.setState({markerPos});
  }

  handleRadiusChange = (e) => {

    let val;

    if (typeof e.target?.value === "string") {
      val = parseInt(e.target.value);
    } else {
      val = parseInt(e);
      const roundNum = e > 6000 ? 1000 : e > 3000 ? 500 : 200;
      val = Math.round(val / roundNum) * roundNum;
    }


    if (val < 0) {
      val = 0;
    } else if (val > 50000) {
      val = 50000;
    }

    this.setState({searchRadius: val});
  }

  handlePositionChange = (e) => {
    let markerPos = this.state.markerPos;
    markerPos[e.target.name] = e.target.value;

    this.setState({markerPos});
  }

  render() {
    return (
      <Accordion allowMultipleExpanded allowZeroExpanded onChange={() => this.setState({open: !this.state.open})}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton
              className="flex items-center mt-5 cursor-pointer w-min text-gray-400 hover:text-gray-900 outline-none focus-visible:text-gray-900 focus-visible:underline">
              <span className="bg-gray-200 rounded mr-2">
                <ChevronRightIcon
                  className={"h-5 w-5 p-0 " + (this.state.open ? "transform rotate-60" : "")}/>
              </span>
              <p className="text-lg inline whitespace-nowrap text-black">Test place suggestion query</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4">
              <NumberField
                className="col-span-1"
                title="Latitude"
                name="lat"
                mode="edit"
                value={this.state.markerPos?.lat}
                onChange={this.handlePositionChange}
              />

              <NumberField
                className="col-span-1"
                title="Longitude"
                name="lng"
                mode="edit"
                value={this.state.markerPos?.lng}
                onChange={this.handlePositionChange}
              />

              <RangeField
                className="col-span-2 xs:col-span-1"
                title="Radius (m)"
                name="searchRadius"
                mode="edit"
                value={this.state.searchRadius}
                step={1000}
                min={1000}
                max={50000}
                onChange={this.handleRadiusChange}
              />

              <button
                type="button"
                className="py-2 disabled:opacity-50 disabled:cursor-default  px-8 flex col-span-2 xs:col-span-1 items-center mx-auto bg-purple-400 hover:bg-purple-600 disabled:bg-purple-400 focus:ring-purple-400 focus:ring-offset-purple-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                onClick={() => this.props.handleSubmitSearchPlaces(this.state.markerPos, this.state.searchRadius)}
                disabled={this.state.markerPos === null || this.props.suggested_places_query === ""}
              >
                <SearchIcon className="h-5 w-5 mr-1.5 text-white"/>
                Search places
              </button>
            </div>

            <div className="mx-auto w-min whitespace-nowrap mt-3 text-xl">
              <button
                className={!this.state.viewResults ? "font-bold" : ""}
                onClick={() => this.setState({viewResults: false})}
              >
                Map
              </button>
              <button
                disabled={this.props.suggested_places.nearby_results.length + this.props.suggested_places.extended_results.length === 0}
                className={"ml-3 disabled:cursor-default disabled:opacity-40 " + (this.state.viewResults ? "font-bold" : "")}
                onClick={() => this.setState({viewResults: true})}
              >
                Results
                {this.props.suggested_places.nearby_results.length + this.props.suggested_places.extended_results.length > 0 ?
                  "(" + (this.props.suggested_places.nearby_results.length + this.props.suggested_places.extended_results.length) + ")"
                  : null}
              </button>
            </div>
            <div className={this.state.viewResults ? "hidden" : ""}>
              {
                this.state.mapLoaded
                  ?
                  <MyMapComponent
                    suggested_places={this.props.suggested_places}
                    updateMarkerPos={this.updateMarkerPos}
                    markerPos={this.state.markerPos}
                    radius={this.state.searchRadius}
                  />
                  :
                  null
              }
            </div>
            <div className={this.state.viewResults ? "" : "hidden"}>
              <p className="text-center font-bold text-gray-500 mt-4">
                Within search radius ({this.props.suggested_places.nearby_results.length})
              </p>
              <hr/>
              <SuggestedPlacesList suggestedPlaces={this.props.suggested_places.nearby_results}
                                   markerPos={this.state.markerPos}/>
              {
                this.props.suggested_places.extended_results.length > 0
                  ?
                  <>
                    <p className="text-center font-bold text-gray-500 mt-4">
                      Outside search radius ({this.props.suggested_places.extended_results.length})
                    </p>
                    <hr/>
                    <SuggestedPlacesList suggestedPlaces={this.props.suggested_places.extended_results}
                                         markerPos={this.state.markerPos}/>
                  </>
                  : null
              }
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}