import React from "react";
import {useQuery} from "react-query";
import {getRequest} from "../../requestHandler";
import inMemoryJwt from "../../inMemoryJwt";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import {useLocation} from "react-router-dom";
import {generateTotalDateIdeasData} from "./StatsTestDataUtil";
import RefreshDataButton from "./RefreshDataButton";

const override = css`
  display: block;
  margin: 1.25rem auto auto auto;
`;

const queryKey = 'totalIdeaCount';

export default function IdeasCountCard() {

  const fetchTotalDateIdeas = async () => {
    if (urlSearchParams.get('data-source') === "test") {
      return generateTotalDateIdeasData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/ideas/total-ideas`, config)
        .catch((error) => {
          throw Error(error);
        });

      return res.data.total_ideas;
    }
  }

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(queryKey, fetchTotalDateIdeas, {
    enabled: false,
  });

  if (isIdle) {
    refetch();
    return (
      <div className="col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 123}}>
        <p>Total Ideas</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 123}}>
        <p>Total Ideas</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 123}}>
        <p>Total Ideas</p>
        <p className="text-gray-500" style={{marginTop: "1.25rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  return (
    <div className="col-span-1 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative"
         style={{minHeight: 123}}>
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={queryKey}/>
      <p>Total Ideas</p>
      <h1 className="font-medium text-gray-700" style={{fontSize: "50px"}}>{data}</h1>
    </div>
  );
}