import {isMobile} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router-dom";
import React, {Component} from "react";

class ViewedDateIdeasListItem extends Component {
  render() {
    return <li
      className={"flex flex-row items-center px-4 py-2 justify-between cursor-pointer hover:bg-gray-100 rounded-lg " + (isMobile ? "" : "mr-2")}
      onClick={() => this.props.history.push({
        pathname: `/idea/${this.props.id}`,
        state: {fromStats: true},
      })}
    >
      <div className="mr-7" style={{minWidth: 0}}>
        <div className="font-medium text-left">
          {this.props.title}
        </div>
        <p className="text-sm text-gray-500 overflow-ellipsis text-left" style={{maxWidth: "100%"}}>
          {
            this.props.labels
              .filter((label) => label !== "Any" && label !== "None")
              .join(', ')
          }
        </p>
      </div>
      <div className="text-gray-600 text-sm">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faEye} className="mr-1.5 text-gray-500"/>
          <p className="text-xl">{this.props.views}</p>
        </div>
        <div className="flex items-center mx-auto mt-1.5">
          <FontAwesomeIcon icon={faSquareCheck} className="mr-1.5 text-gray-500"/>
          <p className="text-xl">{this.props.completionRate !== null ? this.props.completionRate : "N/A"}</p>
        </div>
      </div>
    </li>;
  }
}

export default withRouter(ViewedDateIdeasListItem);