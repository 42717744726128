import TextareaAutosize from "react-textarea-autosize";
import React, {Component} from "react";
import {LightBulbIcon} from "@heroicons/react/outline";
import MultiSelectDropDownMenu from "./MultiSelectDropDownMenu";

export default class TextField extends Component {
  state = {
    showPrompts: false,
    labelSearch: "",
  }

  handleSearch = (e) => {
    this.setState({labelSearch: e.target.value});
  }

  handlePromptClick = (e) => {
    this.setState({labelSearch: "",});
    this.props.handlePromptClick(e);
  }

  render() {
    return <div
      className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className + (this.props.disabled ? " opacity-50" : "")}>
      <div className="flex">
        <h4 className="mb-1 text-gray-400 font-medium text-sm">{this.props.title}</h4>
        {
          this.props.prompts && this.props.mode !== "view"
            ?
            <button
              className="flex items-center text-xs text-gray-300 ml-2 italic hover:text-gray-400 content-start mb-2 disabled:cursor-default disabled:text-gray-300 outline-none focus-visible:text-gray-400"
              onClick={() => this.props.handleDropdown(this.props.name)}
              disabled={this.props.disabled}
            >
              <LightBulbIcon className="h-4 w-4 mr-0.5"/>
              Prompts
            </button>
            :
            null
        }
      </div>
      <p className="text-red-700 text-xs">{this.props.error}</p>
      {
        this.props.mode === "view"
          ?
          <p className="whitespace-pre-line">{this.props.value ? this.props.value : "N/A"}</p>
          :
          this.props.multiline ?
            <TextareaAutosize
              className="w-full bg-transparent focus:bg-white focus:outline-none hover:bg-gray-200 transition ease-in duration-100 rounded resize-none"
              maxRows={8}
              disabled={this.props.disabled}
              placeholder={this.props.placeholder}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              autoComplete="off"
            />
            :
            <input
              type="text"
              className={"w-full bg-transparent transition ease-in duration-100 rounded resize-none " + (this.props.disabled ? "" : "focus:bg-white focus:outline-none hover:bg-gray-200")}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              autoComplete="off"
            />
      }
      {
        this.props.selected === this.props.name
          ?
          <MultiSelectDropDownMenu
            id={this.props.name}
            nested
            handleDropdown={this.props.handleDropdown}
            availableLabels={this.props.promptValues}
            flattenedAvailableLabels={this.props.flattenedPromptValues}
            labelSearch={this.state.labelSearch}
            onChange={this.handleSearch}
            handleClearSearch={() => this.setState({labelSearch: ""})}
            handleDropdownClick={this.handlePromptClick}
            showConfirmationModal={() => this.setState({confirmationModalOpen: true})}
          />
          :
          null
      }
    </div>;
  }
}