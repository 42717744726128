import {format} from "date-fns";
import {Component} from "react";

export default class CreatedAndUpdatedInfo extends Component {
  render() {
    return (
      this.props.mode === "view"
        ?
        <div className="md:col-span-3">
          {
            this.props.idea.created !== undefined
              ?
              <div
                className="md:float-right block sm:flex md:block mb-2 py-2 px-3 bg-gray-100 shadow-inner rounded-lg right">
                <h5 title={"By " + this.props.idea.created_by}
                    className="text-sm font-mono whitespace-nowrap">
                  <span className="text-gray-400 mr-1">Created: </span>
                  {format(new Date(this.props.idea.created), "do MMM yyyy")}
                </h5>
                {
                  this.props.idea.last_updated !== null
                    ?
                    <h5 title={"By " + this.props.idea.updated_by}
                        className="ml-0 sm:ml-5 md:ml-0 text-sm font-mono whitespace-nowrap">
                      <span className="text-gray-400 mr-1">Updated: </span>
                      {format(new Date(this.props.idea.last_updated), "do MMM yyyy")}
                    </h5>
                    : null
                }
              </div>
              : null
          }
        </div>
        :
        null
    );
  }
}