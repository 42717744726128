import React, {useState} from "react";
import {
  ChartLabel,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  YAxis
} from "react-vis";
import {useHistory, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {generateLabelDurationDistData} from "./StatsTestDataUtil";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {css} from "@emotion/react";
import {useQuery} from "react-query";
import {PuffLoader} from "react-spinners";
import RefreshDataButton from "./RefreshDataButton";

const tipStyle = {
  display: 'block',
  color: '#000',
  background: 'rgba(255,255,255,0.85)',
  alignItems: 'center',
};

const override = css`
  display: block;
  margin: 10rem auto auto auto;
`;

export default function LabelDurationDistributionCard(props) {

  const fetchLabelDurationDistData = async () => {
    if (urlSearchParams.get('data-source') === "test") {
      return generateLabelDurationDistData();
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/labels/duration-distribution/${props.selectedLabelType}`, config)
        .catch((error) => {
          throw Error(error);
        });

      return res.data;
    }
  }

  const [hoveredCell, setHoveredCell] = useState(false);
  const [focused, setFocused] = useState(false);
  const [tooltipFocused, setTooltipFocused] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(
    `${props.selectedLabelType}DurationDist`,
    fetchLabelDurationDistData,
    {enabled: false,}
  );

  if (isIdle) {
    refetch();
    return (
      <div
        className="col-span-2 lg:col-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Duration Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="col-span-2 lg:col-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Duration Distribution by Label</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div
        className="col-span-2 lg:col-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
        style={{minHeight: 410}}>
        <p>Duration Distribution by Label</p>
        <p className="text-gray-500" style={{marginTop: "10rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  const getValuesDesc = () => {
    return (
      data.duration_distribution
        .map(e => e.find(o => o.x === hoveredCell.x))
        .sort((a, b) => b.y - a.y)
    );
  }

  const getMidValue = (value) => {
    let values = data.duration_distribution.map(e => e.find(o => o.x === value.x).y);
    return Math.min(...values) + (Math.max(...values) - Math.min(...values)) / 2;
  }

  const handleOnNearestX = (value) => {
    if (value.x !== hoveredCell.x && !focused) {
      const y = getMidValue(value);
      setHoveredCell({...value, y: y});
    }
  }

  const handleOnMouseLeave = () => {
    if (!tooltipFocused) {
      setHoveredCell(false);
      setFocused(false);
    }
  }

  const handleOnClick = () => {
    setFocused(!focused);
  }

  const handleTooltipOnMouseEnter = () => {
    if (focused) {
      setTooltipFocused(true);
    }
  }

  const handleTooltipOnMouseLeave = (e) => {
    if (e.relatedTarget.closest(".label-duration-dist-graph") === null) {
      setTooltipFocused(false);
      setHoveredCell(false);
      setFocused(false);
    } else {
      setTooltipFocused(false);
    }
  }


  return (
    <div className="col-span-2 lg:col-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow relative"
         style={{minHeight: 410}}>
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={`${props.selectedLabelType}DurationDist`}/>
      <p>Duration Distribution by Label</p>
      <DiscreteColorLegend
        orientation="horizontal"
        className="mx-4 my-2 scrollbar scrollbar-thin scrollbar-thumb-gray-100 hover:scrollbar-thumb-gray-200 scrollbar-thumb-rounded-full"
        items={data.legend_items}
      />
      <div className="mx-4 touch-none" style={{height: "calc(100% - 24px)"}}
           onMouseLeave={handleOnMouseLeave}
           onClick={handleOnClick}
      >
        <FlexibleWidthXYPlot
          height={300}
          className="label-duration-dist-graph"
          style={{touchAction: "none"}}
        >
          <VerticalGridLines/>
          <HorizontalGridLines/>
          <XAxis
            tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            tickFormat={d => d === 10 ? d + "+" : d}
          />
          <ChartLabel
            text="Number of ideas"
            className="alt-y-label"
            includeMargin={false}
            xPercent={isMobile ? -0.12 : -0.028}
            yPercent={0.38}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'end'
            }}
          />
          <ChartLabel
            text="Hours"
            className="alt-x-label"
            includeMargin={false}
            xPercent={isMobile ? 0.445 : 0.485}
            yPercent={1.19}
          />
          <YAxis
            tickFormat={d => d - Math.floor(d) === 0.5 ? "" : d.toFixed(0)}
          />
          {data.duration_distribution.map((categoryData, i) =>
            <LineSeries
              key={`duration-distribution-line-series-${i}`}
              curve={'curveMonotoneX'}
              color={data.color_palette[i]}
              data={categoryData}
              onNearestX={(value) => i === 0 ? handleOnNearestX(value) : null}
            />
          )}
          {hoveredCell && (
            <Hint value={hoveredCell} align={{
              vertical: 'top',
              horizontal: hoveredCell.x >= data.legend_items.length / 2 || hoveredCell.x === "10+" ? 'left' : 'right'
            }}>
              <div className={"px-2 py-1 rounded-lg border-gray-400 border-2 "
                + (focused ? "opacity-100 pointer-events-auto" : "opacity-60 ")}
                   style={tipStyle}
                   onMouseEnter={handleTooltipOnMouseEnter}
                   onMouseLeave={handleTooltipOnMouseLeave}
              >
                <b>~{hoveredCell.x} hours</b><br/>
                <div className="text-left mt-1 mx-auto w-min whitespace-nowrap">
                  {
                    getValuesDesc().map((item, i) =>
                      <div
                        key={`duration-tooltip-item-${i}`}
                        className="hover:bg-gray-200 cursor-pointer rounded-md px-2 pb-0.5"
                        onClick={() => history.push({
                          pathname: `/`,
                          search: `min_duration_hrs=${item.x}&max_duration_hrs=${item.x + 0.99}&categories=["${item.label}"]`,
                        })}
                      >
                        <span style={{
                          color: data.legend_items.find(e => e.title === item.label).color,
                          fontSize: 20
                        }}>● </span>
                        {item.label}: {item.y}<br/>
                      </div>
                    )
                  }
                </div>
              </div>
            </Hint>
          )}
        </FlexibleWidthXYPlot>
      </div>
    </div>);
}