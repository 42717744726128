import {Component} from "react";

export default class NumberField extends Component {
  render() {
    return <div className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className}>
      <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">{this.props.title}</h4>
      <p className="text-red-700 text-xs">{this.props.error}</p>
      {
        this.props.mode === "view"
          ?
          <p>{this.props.value}</p>
          :
          <input
            type="number"
            className="w-full bg-transparent focus:bg-white focus:outline-none hover:bg-gray-200 transition ease-in duration-100 rounded resize-none"
            placeholder="?"
            name={this.props.name}
            value={this.props.value || ''}
            onChange={this.props.onChange}
          />
      }
    </div>;
  }
}

export class PriceField extends NumberField {
  render() {
    return (
      <div className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className}>
        <h4 className="text-gray-400 font-medium text-sm whitespace-nowrap">{this.props.title}</h4>
        <p className="text-red-700 text-xs">{this.props.error}</p>
        {
          this.props.mode === "view"
            ?
            <div
              className="flex text-gray-300 font-black text-lg bg-transparent rounded resize-none"
            >
              {
                [1, 2, 3, 4].map(val => <p key={'price_' + val}
                                           className={"mr-1.5 " + (val <= this.props.value ? "text-gray-500" : "")}>£</p>)
              }
            </div>
            :
            <button
              className="flex text-gray-300 font-black text-lg w-full bg-transparent focus:outline-none hover:bg-gray-200 focus-visible:bg-gray-200 transition ease-in duration-100 rounded resize-none"
              onClick={this.props.onClick}
              onKeyPress={(e) => {
                if (e.key !== ' ' && e.key <= 4 && e.key >= 0) {
                  this.props.onKeyPress(e);
                }
              }
              }
            >
              {
                [1, 2, 3, 4].map(val => <p key={'price_' + val}
                                           className={"mr-1.5 " + (val <= this.props.value ? "text-gray-500" : "")}>£</p>)
              }
            </button>
        }
      </div>
    );
  }
}