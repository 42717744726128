import {Component} from "react";

export default class BoolField extends Component {
  render() {
    return (
      <div className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className}>
        <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">{this.props.title}</h4>
        <p className="text-red-700 text-xs">{this.props.error}</p>
        {
          this.props.mode === "view"
            ?
            <p>{this.props.value === true ? "True" : "False"}</p>
            :
            <button
              id={this.props.id}
              className={"w-full text-left active:bg-gray-300 outline-none focus-visible:bg-gray-200 transition ease-in duration-100 hover:bg-gray-200 rounded " + (this.props.value === "" ? "text-gray-400" : "")}
              onClick={this.props.onClick}
            >
              <p id={this.props.id}>
                {
                  this.props.value === ""
                    ? "?"
                    : this.props.value === true ? "True" : "False"
                }
              </p>
            </button>
        }
      </div>
    );
  }
}