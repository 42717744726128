import {Component} from "react";
import TableRow, {TableRowPlaceholder} from "./TableRow";
import TablePager from "./TablePager";

class ColumnHeader extends Component {
  render() {
    return (
      <th scope="col"
          className={this.props.width + " px-5 py-3 bg-white border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"}>
        {this.props.label}
      </th>
    );
  }
}

export default class Table extends Component {
  render() {
    return (
      <div className="container mx-auto px-4 sm:px-8 max-w-7xl">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal table-fixed">
              <thead>
              <tr>
                <ColumnHeader label="Title"/>
                <ColumnHeader label="Description" width="w-1/2"/>
                <ColumnHeader label="Categories" width="w-1/3"/>
              </tr>
              </thead>
              <tbody>
              {
                !this.props.fetchingIdeas
                  ?
                  this.props.ideas?.map(idea => <TableRow key={idea.id} idea={idea}
                                                          highlightText={this.props.highlightText}/>)
                  :
                  <>
                    <TableRowPlaceholder/>
                    <TableRowPlaceholder/>
                  </>
              }
              </tbody>
            </table>
            <div className="px-5 bg-white py-5 flex flex-col items-center">
              <TablePager pageIndex={this.props.pageIndex} total={this.props.total} maxIdeas={this.props.maxIdeas}
                          handlePagerClick={this.props.handlePagerClick}/>
              {this.props.fetchingIdeas
                ?
                <p className="text-sm text-gray-400 mt-2">
                  Fetching date ideas...
                </p>
                :
                <p className="text-sm text-gray-400 mt-2">
                  Showing {this.props.total <= this.props.ideas?.length ? this.props.total : this.props.ideas?.length} of {this.props.total} date
                  ideas
                </p>
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}