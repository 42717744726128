import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {FilterIcon, PlusIcon, SearchIcon, SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/solid";
import {XIcon} from "@heroicons/react/outline";

class MainViewControls extends Component {
  state = {
    filterSettings: {}
  }

  componentDidMount() {
    this.extractFilterSettingsFromUrlQueryParams();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.extractFilterSettingsFromUrlQueryParams();
    }
  }

  extractFilterSettingsFromUrlQueryParams() {
    let urlSearchParams = new URLSearchParams(this.props.location.search);

    urlSearchParams.delete('limit');
    urlSearchParams.delete('offset');

    const filterSettings = {};

    for (let key of urlSearchParams.keys()) {
      const keysToNotParse = ['query', 'sort_by'];
      if (!keysToNotParse.includes(key)) {
        filterSettings[key] = JSON.parse(urlSearchParams.get(key));
      }
    }

    this.setState({filterSettings});
  }

  onAddClick = () => {
    return this.props.history.push({
      pathname: `/idea/create`,
      state: {mode: "create", fromState: this.props.locationState}
    });
  }

  render() {
    return (
      <div>
        <div
          className="md:hidden mx-auto flex cursor-text items-center py-1 pl-2 pr-3 mt-5 bg-white shadow rounded-2xl whitespace-nowrap hover:bg-gray-200 transition ease-in duration-100"
          style={{maxWidth: "70vw"}}
        >
          <SearchIcon className="h-4 w-4 mr-1 text-gray-700"/>
          <input
            className="focus:outline-none bg-transparent w-full"
            placeholder="Search..."
            value={this.props.ideaSearch}
            onChange={this.props.handleSearchChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.props.handleSearchSubmit()
              }
            }}
            onBlur={this.props.handleSearchSubmit}
          />
        </div>
        <div className="flex container justify-between mx-auto px-4 sm:px-8 max-w-7xl mt-4">
          <div className="flex">
            <div
              className="hidden md:flex cursor-text items-center py-1 pl-2 pr-3 mr-2 bg-white shadow rounded-2xl whitespace-nowrap hover:bg-gray-200 transition ease-in duration-100"
            >
              <SearchIcon className="h-4 w-4 mr-1 text-gray-700"/>
              <input
                className="focus:outline-none bg-transparent"
                style={{width: '250px'}}
                placeholder="Search..."
                value={this.props.ideaSearch}
                onChange={this.props.handleSearchChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.props.handleSearchSubmit()
                  }
                }}
                onBlur={this.props.handleSearchSubmit}
              />
            </div>
            <button
              className={"flex justify-center items-center max-w-min py-1 pl-2 pr-3 bg-white shadow rounded-2xl whitespace-nowrap transition ease-in duration-100 "
                + (Object.keys(this.state.filterSettings).length > 0 && this.props.availableFiltersLoaded ? "text-blue-700 " : "text-grey-700 ")
                + (this.props.availableFiltersLoaded ? "hover:bg-gray-200" : "opacity-50 cursor-auto")
              }
              onClick={() => this.props.availableFiltersLoaded ? this.props.handleShowFilter(true) : null}
            >
              <FilterIcon
                className="h-4 w-4 mr-1"
              />
              Filter
            </button>
            {
              Object.keys(this.state.filterSettings).length > 0 && this.props.availableFiltersLoaded
                ?
                <button
                  className="flex justify-center items-center max-w-min my-1 ml-2 mr-3 whitespace-nowrap transition text-sm ease-in duration-100"
                  onClick={this.props.handleClearFilter}
                >
                  <XIcon
                    className="h-4 w-4 mr-1"
                  />
                  Clear
                </button>
                :
                null
            }
            <button
              className="flex justify-center items-center max-w-min my-1 ml-2 mr-3 whitespace-nowrap transition text-sm ease-in duration-100"
              onClick={this.props.handleSort}
            >
              {
                this.props.ascDesc === "desc" ? <SortDescendingIcon
                  className="h-4 w-4 mr-1"
                /> : <SortAscendingIcon
                  className="h-4 w-4 mr-1"
                />
              }
              Sort
            </button>
          </div>
          <button
            className="flex justify-center items-center max-w-min py-1 pl-2 pr-3 ml-2 bg-white shadow rounded-2xl whitespace-nowrap hover:bg-gray-200 transition ease-in duration-100"
            onClick={this.onAddClick}>
            <PlusIcon className="h-4 w-4 mr-1 text-gray-700"/>
            Create
          </button>
        </div>
      </div>
    )
  }
}

export default withRouter(MainViewControls);
