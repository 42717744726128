import _ from "lodash";
import {loremIpsum} from "lorem-ipsum";

const MAX_IDEAS = 300;
const CATEGORY_CHOICES = ["Arts", "Chill", "Cute", "Exciting", "Food", "Fun", "Memories", "Messy", "Romantic"];
const WEATHER_CHOICES = ["Sunny", "Rainy", "Cloudy", "Snowy", "Cold", "Hot"];
const TIME_CHOICES = ["Morning", "Afternoon", "Daytime", "After Dark", "Evening", "Dinner", "Lunch", "Sunset"];
const SEASON_CHOICES = ["Summer", "Winter", "August", "Spring", "Christmas", "Easter"]
const EQUIPMENT_CHOICES = ["House", "Dog", "Tent", "Car"];

export function generateTotalDateIdeasData() {
  return MAX_IDEAS;
}

export function generateTotalViewsData() {
  return Math.round(MAX_IDEAS * Math.random() * 1000);
}

export function generateIdeaListData() {
  const totalLeaves = MAX_IDEAS || Math.random() * 20;
  const ideas = [];
  for (let i = 0; i < totalLeaves; i++) {
    const labels = [];
    labels.push(..._.sampleSize(CATEGORY_CHOICES, 3));
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(WEATHER_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(TIME_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(EQUIPMENT_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(SEASON_CHOICES));
    }
    const views = 1 + Math.round(Math.pow(Math.random() * 10, (i / MAX_IDEAS) * 4));
    ideas.push({
      title: loremIpsum({
        count: 1,
        sentenceLowerBound: 4,
        sentenceUpperBound: 6,
        units: "sentences",
      }).slice(0, -1),
      id: "f4cc92a5-a80a-4cc9-8b7a-9b9c045ade8f",
      views: views,
      labels: labels,
      color: Math.random(),
      completion_rate: ((Math.round(Math.random() * 700) / 10) + 20).toFixed(1) + "%",
      style: {
        border: 'thin solid white'
      }
    });
  }

  ideas.sort((a, b) => (a.size < b.size) ? 1 : -1);

  return ideas;
}

export function generateIdeaViewDistData() {
  const totalLeaves = MAX_IDEAS || Math.random() * 20;
  const leaves = [];
  for (let i = 0; i < totalLeaves; i++) {
    const labels = [];
    labels.push(..._.sampleSize(CATEGORY_CHOICES, 3));
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(WEATHER_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(TIME_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(EQUIPMENT_CHOICES));
    }
    if (Math.round(Math.random()) > 0) {
      labels.push(_.sample(SEASON_CHOICES));
    }
    const views = 1 + Math.round(Math.pow(Math.random() * 10, (i / MAX_IDEAS) * 4));
    leaves.push({
      name: loremIpsum({
        count: 1,
        sentenceLowerBound: 4,
        sentenceUpperBound: 6,
        units: "sentences",
      }).slice(0, -1),
      id: "f4cc92a5-a80a-4cc9-8b7a-9b9c045ade8f",
      size: views,
      labels: labels,
      color: Math.random(),
      completion_rate: ((Math.round(Math.random() * 700) / 10) + 20).toFixed(1) + "%",
      style: {
        border: 'thin solid white'
      }
    });
  }

  leaves.sort((a, b) => (a.size < b.size) ? 1 : -1);

  return {
    title: '',
    color: 1,
    children: leaves
  };
}

export function generateIdeaCompletionDistData() {
  const res = [];
  let remainingIdeas = MAX_IDEAS;
  let maxY = 0;
  for (let i = 0; i <= 100; i++) {
    const x = i;
    remainingIdeas -= (Math.random() * 10) * remainingIdeas / 200
    const y = remainingIdeas;
    maxY = y > maxY ? y : maxY;
    res.push({
      x: x,
      y: Math.round(y),
    });
  }
  return {
    "completion_rate_distribution": res,
    "max_y": maxY,
  };
}

export function generateLabelIndoorOutdoorDistData() {
  const response = [
    {
      name: 'Spider5',
      fill: '#f8f8f8',
      stroke: '#cccccc'
    },
    {
      name: 'Spider4',
      fill: 'white',
      stroke: '#cccccc'
    },
    {
      name: 'Spider3',
      fill: '#f8f8f8',
      stroke: '#cccccc'
    },
    {
      name: 'Spider2',
      fill: 'white',
      stroke: '#cccccc'
    },
    {
      name: 'Spider1',
      fill: '#f8f8f8',
      stroke: '#cccccc'
    },
    {
      name: 'Spider0',
      fill: '#f8f8f8',
      stroke: '#cccccc'
    },
    {
      name: 'Indoors',
      fill: 'rgba(150,114,240,0.1)',
      // If you update this colour, make sure you update the matching substring attribute selector in app.css too.
      stroke: 'rgba(150,114,240,0.78)',
    },
    {
      name: 'Outdoors',
      fill: 'rgba(225,29,72,0.1)',
      // If you update this colour, make sure you update the matching substring attribute selector in app.css too.
      stroke: 'rgba(225,29,72,0.78)',
    }
  ];

  for (let i = 0; i < 6; i++) {
    CATEGORY_CHOICES.forEach(category => {
      response[i][category] = MAX_IDEAS / 2 / 5 * (5 - i);
    });
  }

  for (let i = 6; i < 8; i++) {
    CATEGORY_CHOICES.forEach(category => {
      response[i][category] = Math.round(Math.random() * ((MAX_IDEAS / 2 / 4) * 3) + (MAX_IDEAS / 2 / 4));
    });
  }

  const radarDomains = [
    {
      name: CATEGORY_CHOICES[0],
      domain: [0, MAX_IDEAS / 2],
      tickFormat: (t => {
        if (t < MAX_IDEAS / 2 && t > 0) {
          return Math.round(t);
        }
        return '';
      })
    },
    ...CATEGORY_CHOICES.slice(1, CATEGORY_CHOICES.length).map(category => {
      return {name: category, domain: [0, MAX_IDEAS / 2], getValue: d => d[category]}
    })
  ];

  return {
    "indoor_outdoor_distribution": response,
    "max_y": MAX_IDEAS / 2,
    "domains": radarDomains,
  };
}

export function generateLabelPriceDistData() {

  const colorPalette = ["#e53157", "#ef6c64", "#f5987d", "#fac0a3", "#fee5d2"];
  const data = [];

  for (let i = 0; i < 5; i++) {
    const colorVal = colorPalette[i];
    data.push(
      CATEGORY_CHOICES.map((choice, j) => {
        return {
          color: colorVal,
          x: Math.round(Math.random() * MAX_IDEAS / 2 * ((5 - i) / 10)),
          y: CATEGORY_CHOICES[j]
        };
      })
    )
  }
  return {
    "price_distribution": data,
    "color_palette": colorPalette,
  };
}

export function generateLabelDurationDistData() {
  const data = []
  CATEGORY_CHOICES.forEach((label) => {
    let multiplier = Math.random() >= 0.7 ? (Math.random() * 0.65 + 0.2) : 1;
    data.push(
      [{x: 1, y: Math.round((80 + Math.random() * 50) * multiplier), label},
        {x: 2, y: Math.round((75 + Math.random() * 50) * multiplier), label},
        {x: 3, y: Math.round((55 + Math.random() * 50) * multiplier), label},
        {x: 4, y: Math.round((30 + Math.random() * 50) * multiplier), label},
        {x: 5, y: Math.round((20 + Math.random() * 50) * multiplier), label},
        {x: 6, y: Math.round((15 + Math.random() * 50) * multiplier), label},
        {x: 7, y: Math.round((10 + Math.random() * 20) * multiplier), label},
        {x: 8, y: Math.round((Math.random() * 20) * multiplier), label},
        {x: 9, y: Math.round((Math.random() * 5) * multiplier), label},
        {x: 10, y: Math.round((Math.random() * 2) * multiplier), label}]);
  });

  const legendColors = ["#9672f07f", "#b990e6aa", "#dbafddd4", "#fecdd3", "#f0758e", "#e11d48", "#e44145", "#e76642",
    "#ea8a3f"];

  const legendItems = CATEGORY_CHOICES.map((category, i) => {
    return {
      title: category,
      strokeWidth: 5,
      color: legendColors[i]
    }
  });

  return {
    "duration_distribution": data,
    "legend_items": legendItems
  };
}