import React, {Component} from 'react';
import {differenceInMinutes, fromUnixTime} from "date-fns";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

export default class RefreshDataButton extends Component {
  getLastUpdatedString = () => {
    const lastUpdatedMinutes = differenceInMinutes(
      new Date(),
      fromUnixTime(Math.round(this.props.dataUpdatedAt / 1000))
    );

    if (lastUpdatedMinutes >= 60) {
      const hours = Math.floor(lastUpdatedMinutes / 60);
      return `Updated ${hours} hour${hours === 1 ? "" : "s"} ago`
    } else {
      return lastUpdatedMinutes < 1
        ? "Updated less than a minute ago"
        : `Updated ${lastUpdatedMinutes} minute${lastUpdatedMinutes === 1 ? "" : "s"} ago`
    }
  }

  render() {
    return <>
      <button data-tip data-place="bottom" data-for={this.props.id}
              className="mr-3 px-1 cursor-pointer text-gray-200 hover:text-gray-400"
              style={{position: "absolute", right: 0, top: "0.5rem"}}
              onClick={this.props.onClick}
      >
        <FontAwesomeIcon icon={faArrowsRotate} size="sm"/>
      </button>
      <ReactTooltip id={this.props.id} effect="solid">
          <span>
            {this.getLastUpdatedString()}
          </span>
      </ReactTooltip>
    </>;
  }
}