import {TEST_DATA_MAX_IDEAS} from "./StatsView";
import CustomSlider from "./CustomSlider";
import React, {useState} from "react";
import {makeVisFlexible, Treemap} from "react-vis";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faSquareCheck, faTags} from '@fortawesome/free-solid-svg-icons'
import {isMobile} from "react-device-detect";
import {generateIdeaViewDistData} from "./StatsTestDataUtil";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import inMemoryJwt from "../../inMemoryJwt";
import {getRequest} from "../../requestHandler";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";
import RefreshDataButton from "./RefreshDataButton";

const FlexTreemap = makeVisFlexible(Treemap);

const tipStyle = {
  display: 'block',
  color: '#000',
  background: 'rgba(255,255,255,0.95)',
  alignItems: 'center',
  maxWidth: isMobile ? "45vw" : "40vw"
};

const override = css`
  display: block;
  margin: 6.5rem auto auto auto;
`;

const queryKey = 'ideaViewDist';

export default function IdeaViewsDistributionCard(props) {

  const sliceTestData = (treeData) => {
    const start = values[0];
    const end = values[1];
    treeData = JSON.parse(JSON.stringify(treeData));
    treeData.children = treeData.children.slice(start, end);
    return treeData;
  }

  const fetchViewDistData = async () => {

    if (urlSearchParams.get('data-source') === "test") {
      const data = generateIdeaViewDistData();
      setViewDistData(sliceTestData(data));
      return data;
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      let res = await getRequest(`/stats/ideas/view-distribution`, config)
        .catch((error) => {
          throw Error(error);
        });
      setViewDistData(sliceTestData(res.data.view_distribution_data));
      return res.data.view_distribution_data;
    }
  }

  const [values, setValues] = useState([0, 100 < TEST_DATA_MAX_IDEAS ? 100 : TEST_DATA_MAX_IDEAS]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const {isLoading, isIdle, isError, data, dataUpdatedAt, refetch} = useQuery(queryKey, fetchViewDistData, {
    enabled: false,
  });
  const [viewDistData, setViewDistData] = useState(data);
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);

  if (isIdle) {
    refetch();
    return (
      <div className="lg:col-span-3 col-span-2 rounded-lg py-3 px-2 text-center bg-white filter card-shadow"
           style={{minHeight: 348}}>
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="col-span-2 row-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow max-h-96 relative"
        style={{height: 309}}
      >
        <p>Total Views</p>
        <PuffLoader color={"#737373"} css={override} size={40}/>
      </div>
    );
  }

  if (isError) {
    return (
      <div
        className="col-span-2 row-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow max-h-96 relative"
        style={{height: 309}}
      >
        <p>Total Views</p>
        <p className="text-gray-500" style={{marginTop: "6.5rem"}}>
          Error loading data!
        </p>
      </div>
    );
  }

  const handleSliderChange = (values) => {
    if (values[1] - values[0] > 6) {
      setValues(values);
      setViewDistData(sliceTestData(data));
    }
  }

  const getTooltipPosition = (hoveredNode) => {
    const topPadding = 73 + (props.showSlider ? 36 : 0),
      xPadding = 9,
      chartWidth = hoveredNode.parent.x1;
    if (hoveredNode.x0 < chartWidth / 2) {
      // Left side of treemap
      return {
        top: hoveredNode.y0 + ((hoveredNode.y1 - hoveredNode.y0) / 2) + topPadding,
        left: hoveredNode.x1 - ((hoveredNode.x1 - hoveredNode.x0) / 3) + xPadding
      };
    } else {
      // Right side of treemap
      return {
        top: hoveredNode.y0 + ((hoveredNode.y1 - hoveredNode.y0) / 2) + topPadding,
        right: chartWidth - hoveredNode.x0 - ((hoveredNode.x1 - hoveredNode.x0) / 3) + xPadding
      };
    }
  }

  const handleOnLeafOver = (node, _) => {
    if (!isMobile) {
      setHoveredNode(node);
    }
  }

  const handleOnLeafOut = (node, e) => {
    try {
      if (e.relatedTarget.closest(".rv-treemap__leaf") === null
        && e.relatedTarget.closest(".treemap-tooltip") === null) {
        setHoveredNode(null);
      }
    } catch {
      // Cursor went to window element
      setHoveredNode(null);
    }
  }

  const handleLeafClick = (node, _) => {
    if (isMobile && (hoveredNode?.x0 !== node.x0 || hoveredNode?.y0 !== node.y0)) {
      setHoveredNode(node);
    } else {
      history.push({
        pathname: `/idea/${node.data.id}`,
        state: {fromStats: true},
      });
    }
  }

  const valueRange = () => {
    if (data.children.length > 100) {
      return (Math.ceil(values[0]) + 1) + "-" + (Math.ceil(values[1]));
    } else {
      return data.children.length;
    }
  }

  return (
    <div
      className="col-span-2 row-span-3 rounded-lg py-3 px-2 text-center bg-white filter card-shadow max-h-96 relative"
      style={{height: 309}}
    >
      <RefreshDataButton onClick={refetch} dataUpdatedAt={dataUpdatedAt} id={queryKey}/>
      <p className="mb-3">
        {valueRange()} Most Viewed Ideas
      </p>
      <button
        className="-top-2.5 relative text-red-500 disabled:text-gray-300 disabled:cursor-auto"
        onClick={props.handleShowSlider}
        disabled={data.children.length <= 100}
      >
        {props.showSlider === true ? "Hide" : "Show"} slider
      </button>
      {
        props.showSlider
          ? <CustomSlider values={values} onChange={handleSliderChange}/>
          : null
      }
      <div style={{minHeight: "225px", height: "1px", padding: "-20px"}}>
        <div className="w-full h-full overflow-hidden rounded-xl">
          <div className="relative" style={{height: "calc(100% + 50px)", width: "calc(100% + 50px)"}}>
            <FlexTreemap
              title={"My New Treemap"}
              data={viewDistData}
              mode="binary"
              padding={0}
              onLeafMouseOver={handleOnLeafOver}
              onLeafMouseOut={handleOnLeafOut}
              onLeafClick={handleLeafClick}
              colorRange={["#e53157", "#fac0a3"]}
              hideRootNode
            />
          </div>
        </div>
        {
          hoveredNode &&
          <div className="px-4 py-1 rounded-lg border-gray-400 border-2 absolute treemap-tooltip pointer-events-none"
               style={{...tipStyle, ...getTooltipPosition(hoveredNode), zIndex: 100}}
               onMouseLeave={(e) => handleOnLeafOut(null, e)}>
            <b>{hoveredNode.data.name}</b>
            <div className="flex items-center justify-center max-w-full text-gray-600">
              <FontAwesomeIcon icon={faTags} className="mr-1.5"/>
              <p className="text-sm overflow-ellipsis overflow-hidden whitespace-nowrap">
                {
                  hoveredNode.data.labels
                    .filter((label) => label !== "Any" && label !== "None")
                    .join(", ")
                }
              </p>
            </div>
            <div className="flex w-full justify-center">
              <div className="flex pr-6 items-center text-gray-600">
                <FontAwesomeIcon icon={faEye} className="mr-1.5"/>
                <p>{hoveredNode.value}</p>
              </div>
              <div className="flex items-center text-gray-600">
                <FontAwesomeIcon icon={faSquareCheck} className="mr-1.5"/>
                <p>{hoveredNode.data.completion_rate}</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}