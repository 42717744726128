import {Component} from "react";
import {XIcon} from "@heroicons/react/solid";
import MultiSelectDropDownMenu from "./MultiSelectDropDownMenu";
import NewLabelConfirmationModal from "./NewLabelConfirmationModal";
import inMemoryJwt from "../../inMemoryJwt";
import {withRouter} from "react-router-dom";
import {getRequest, postRequest} from "../../requestHandler";

class Label extends Component {
  colours = ["indigo", "blue", "red", "yellow", "purple", "green", "teal", "lime", "orange", "fuchsia"];

  getLabelColour = (value) => {
    let unicode_sum = 0;
    Array.from(value).forEach(letter => {
      unicode_sum += letter.charCodeAt(0);
    })

    const index = unicode_sum % this.colours.length;

    const colour = this.colours[index];

    return `bg-${colour}-300`;
  }

  render() {
    const bgColor = this.props.color ?? this.getLabelColour(this.props.value);

    return (
      <span
        id="label-container"
        className={"relative mx-0.5 inline-block pl-3 pb-0.5 text-gray-700 leading-tight " + (this.props.mode === "view" ? "pr-3" : "pr-1")}
      >
        <span aria-hidden="true"
              className={"absolute inset-0 opacity-50 rounded-full " + bgColor}
        />
        <span id={this.props.name} className="relative flex">
          {this.props.value}
          {
            this.props.mode !== "view"
              ?
              <button id={this.props.id} onClick={this.props.handleLabelRemove}>
                <XIcon className="h-full w-3 mx-1 mt-0.5"/>
              </button>
              :
              null
          }
        </span>
      </span>
    );
  }
}

class MultiSelectField extends Component {
  state = {
    error: "",
    availableLabels: [],
    flattenedAvailableLabels: [],
    labelSearch: "",
    confirmationModalOpen: false,
  }

  async componentDidMount() {
    if (this.props.location.state?.fromState?.availableLabels !== undefined) {
      this.setState({
        availableLabels: this.props.location.state.fromState.availableLabels[this.props.id]
      });
    } else {
      const accessToken = await inMemoryJwt.getToken();
      const config = {headers: {Authorization: `Bearer ${accessToken}`}};
      await getRequest(
        `/ideas/labels/${this.props.id}`, config)
        .then(res => {
          let labels = res.data.data;
          if (this.props.id === "required_equipment") {
            labels.unshift("None");
          } else if (this.props.id !== "categories") {
            labels.unshift("Any");
          }
          this.setState({availableLabels: labels});
        })
        .catch(err => {
          console.error(err.response?.data || err);
        })
    }
  }

  handleSearch = (e) => {
    this.setState({labelSearch: e.target.value});
  }

  handleDropdownClick = (e) => {
    this.setState({labelSearch: ""});
    this.props.handleDropdownClick(e);
  }

  handleCreateLabel = async () => {
    let label = this.state.labelSearch.charAt(0).toUpperCase() + this.state.labelSearch.slice(1)
    const body = {label};

    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};
    postRequest(
      `/ideas/labels/${this.props.id}`, body, config)
      .then(() => {
        let availableLabels = JSON.parse(JSON.stringify(this.state.availableLabels));
        availableLabels.push(label);
        availableLabels.sort();
        this.setState({
          availableLabels,
          confirmationModalOpen: false,
          labelSearch: ""
        });
      })
      .catch(err => {
        if (err.response.data.code === "DUPLICATE_VALUE") {
          this.setState({error: this.props.title + " label already exists"})
        }
        console.error(err.response?.data || err);
      })
  }

  render() {
    return (
      <div id={this.props.selected === this.props.id ? "dropdown" : ""}
           className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className}>
        <div id={this.props.id}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">{this.props.title}</h4>
          <p className="text-red-700 text-xs">{this.props.error}</p>
          {
            this.props.mode === "view"
              ?
              this.props.values.map(
                value => <Label color={this.props.labelColour} mode={this.props.mode} key={value} value={value}/>
              )
              :
              <div>
                {
                  this.props.values.map(
                    value => {
                      return (
                        <Label
                          color={this.props.labelColour}
                          key={value}
                          value={value}
                          mode={this.props.mode}
                          id={this.props.id}
                          handleLabelRemove={this.props.handleLabelRemove}
                        />
                      )
                    }
                  )
                }
                <button
                  className={"text-left active:bg-gray-300 focus:outline-none focus-visible:bg-gray-200 transition ease-in duration-100 hover:bg-gray-200 rounded text-gray-400 " + (this.props.values.length === 0 ? "w-full" : "mx-0.5 px-1")}
                  onClick={() => this.props.handleDropdown(this.props.id)}
                  onKeyUp={(e) => {
                    if (e.keyCode === 27) {
                      this.props.handleDropdown("");
                    }
                  }}
                >
                  <p>{this.props.values.length === 0 ? "?" : "Add more..."}</p>
                </button>
              </div>
          }
          {
            this.props.selected === this.props.id ?
              <MultiSelectDropDownMenu
                id={this.props.id}
                values={this.props.values}
                onChange={this.handleSearch}
                handleDropdown={this.props.handleDropdown}
                handleClearSearch={() => this.setState({labelSearch: ""})}
                availableLabels={this.state.availableLabels}
                flattenedAvailableLabels={this.state.flattenedAvailableLabels}
                labelSearch={this.state.labelSearch}
                handleDropdownClick={this.handleDropdownClick}
                showConfirmationModal={() => this.setState({confirmationModalOpen: true})}
              />
              : null
          }

          <NewLabelConfirmationModal
            open={this.state.confirmationModalOpen}
            onRequestClose={() => {
              this.setState({confirmationModalOpen: false})
            }}
            title={this.props.title}
            labelSearch={this.state.labelSearch}
            handleCreateLabel={this.handleCreateLabel}
            error={this.state.error}
            hideConfirmationModal={() => this.setState({confirmationModalOpen: false})}
          />
        </div>
      </div>
    );
  }
}

class NestedMultiSelectField extends MultiSelectField {
  state = {
    error: "",
    availableLabels: [],
    flattenedAvailableLabels: [],
    labelSearch: "",
    confirmationModalOpen: false,
  }

  async componentDidMount() {
    function recursivelyFlattenFoursquareApiCategories(categories, flattenedCategories) {
      categories.forEach(e => {
        flattenedCategories[e.id] = e.name;
        if (e.categories.length > 0) {
          recursivelyFlattenFoursquareApiCategories(e.categories, flattenedCategories);
        }
      });
    }

    const accessToken = await inMemoryJwt.getToken();
    const config = {headers: {Authorization: `Bearer ${accessToken}`}};
    await getRequest(
      `/ideas/place_suggestion/categories`, config)
      .then(res => {
        const categories = res.data.response['categories'];
        const flattenedCategories = {};
        recursivelyFlattenFoursquareApiCategories(categories, flattenedCategories);
        this.setState({
          availableLabels: categories,
          flattenedAvailableLabels: flattenedCategories
        });
      })
      .catch(err => {
        if (err.response.data.code === "DUPLICATE_VALUE") {
          this.setState({error: this.props.title + " label already exists"})
        }
        console.error(err.response?.data || err);
      })
  }

  handleDropdownClick = (e) => {
    this.setState({labelSearch: ""});

    function recursivelyFindLabel(labels, name) {
      for (const label of labels) {
        if (label.name === name) {
          return label
        } else {
          const found = recursivelyFindLabel(label.categories, name);
          if (found) {
            return found;
          }
        }
      }
    }

    function recursivelyGetChildLabels(labels, childLabels) {
      labels.forEach(label => {
        childLabels.push(label.name);
        if (label.categories.length > 0) {
          recursivelyGetChildLabels(label.categories, childLabels);
        }
      })
    }

    const labelValue = e.target.innerText;
    const selectedLabel = recursivelyFindLabel(this.state.availableLabels, labelValue);
    const childLabels = [];
    recursivelyGetChildLabels([selectedLabel], childLabels);
    const labelsToRemove = childLabels.filter(element => this.props.values.includes(element));

    this.props.handleNestedDropdownClick(e, labelsToRemove);
  }

  render() {
    return (
      <div id={this.props.selected === this.props.id ? "dropdown" : ""}
           className={"py-2 px-3 bg-gray-100 shadow-inner rounded-lg " + this.props.className + (this.props.disabled ? " opacity-50" : "")}>
        <div id={this.props.id}>
          <h4 className="text-gray-400 font-medium text-sm mb-1 whitespace-nowrap">{this.props.title}</h4>
          {
            this.props.mode === "view"
              ?
              !this.props.readOnly && this.props.values.length > 0
                ?
                this.props.values.map(value =>
                  <Label
                    color={this.props.labelColour}
                    mode={this.props.mode}
                    key={value}
                    value={value}
                  />
                )
                :
                <p className="text-gray-400">None</p>
              :
              <div>
                {
                  this.props.values !== undefined
                    ?
                    this.props.values.map(
                      value => {
                        return (
                          <Label
                            color={this.props.labelColour}
                            key={value}
                            value={value}
                            mode={this.props.mode}
                            id={this.props.id}
                            name={value}
                            handleLabelRemove={this.props.handleLabelRemove}
                          />
                        )
                      }
                    )
                    : null
                }
                <button
                  className={"text-left active:bg-gray-300 focus:outline-none transition ease-in duration-100 hover:bg-gray-200 rounded text-gray-400 " + (this.props.values?.length === 0 ? "w-full" : "mx-0.5 px-1")}
                  onClick={() => this.props.handleDropdown(this.props.id)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 27) {
                      this.props.handleDropdown("");
                    }
                  }}
                >
                  <p>{this.props.values === undefined ? "View prompts" : this.props.values?.length === 0 ? "?" : "Add more..."}</p>
                </button>
              </div>
          }
          <p className="text-red-700 text-xs">{this.props.error}</p>
          {
            this.props.selected === this.props.id ?
              <MultiSelectDropDownMenu
                id={this.props.id}
                values={this.props.values}
                nested
                onChange={this.handleSearch}
                handleDropdown={this.props.handleDropdown}
                handleClearSearch={() => this.setState({labelSearch: ""})}
                availableLabels={this.state.availableLabels}
                flattenedAvailableLabels={this.state.flattenedAvailableLabels}
                labelSearch={this.state.labelSearch}
                handleDropdownClick={this.props.handleDropdownClick === undefined ? () => {
                } : this.handleDropdownClick}
                showConfirmationModal={() => this.setState({confirmationModalOpen: true})}
              />
              : null
          }

          <NewLabelConfirmationModal
            open={this.state.confirmationModalOpen}
            onRequestClose={() => {
              this.setState({confirmationModalOpen: false})
            }}
            title={this.props.title}
            labelSearch={this.state.labelSearch}
            handleCreateLabel={this.handleCreateLabel}
            error={this.state.error}
            hideConfirmationModal={() => this.setState({confirmationModalOpen: false})}
          />
        </div>
      </div>
    );
  }
}


export default withRouter(MultiSelectField);
export {NestedMultiSelectField};