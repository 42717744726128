import {Component} from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import {ChevronRightIcon} from "@heroicons/react/solid";

export class DropDownMenuOptions extends Component {
  render() {
    let selectableLabels = this.props.availableLabels
      .filter(
        x => !this.props.values?.includes(x)
      )
      .filter(
        x => x.toLowerCase()?.includes(this.props.labelSearch.toLowerCase())
      );

    return (
      <>
        {
          selectableLabels.map(label =>
            <button
              key={label}
              className="focus:outline-none focus-visible:bg-gray-200 hover:bg-gray-200 text-left block rounded w-full px-1.5"
              onClick={this.props.handleDropdownClick}
            >
              {label}
            </button>
          )
        }
        {
          selectableLabels?.length <= 2 && this.props.labelSearch?.length > 0
            ?
            <button
              className={"text-left active:bg-gray-300 focus:outline-none focus-visible:bg-gray-200 hover:bg-gray-200 transition ease-in duration-100 rounded text-gray-400 w-full px-1.5"}
              onClick={this.props.showConfirmationModal}
            >
              Create label...
            </button>
            :
            null
        }
      </>
    )
  }
}

class NestedAccordionItem extends Component {
  render() {
    return <AccordionItem
      className={this.props.className}

      uuid={this.props.category.id}
      id="suggested_places_categories"
    >
      <AccordionItemHeading>
        <AccordionItemButton
          className="flex items-center cursor-pointer text-gray-400 hover:text-gray-900 hover:bg-gray-200 focus-within:bg-gray-200 focus-visible:bg-gray-200 focus-visible:text-gray-900 text-left block rounded outline-none"
        >
          {
            this.props.category.categories.length > 0
              ?
              <span className=" rounded outline-none">
                <ChevronRightIcon
                  className={"h-5 w-5 p-0 " + (this.props.open[this.props.category.id] ? "transform rotate-90" : "")}/>
              </span>
              :
              null
          }
          <span
            className="text-left w-full px-1.5 text-gray-900 outline-none focus-visible:underline"
            onClick={this.props.onClick}
            id={this.props.category.id}
          >
            {this.props.flattenedAvailableLabels[this.props.category.id]}
          </span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {
          this.props.open[this.props.category.id]
            ?
            <NestedDropDownMenuOptions
              availableLabels={this.props.category.categories}
              flattenedAvailableLabels={this.props.flattenedAvailableLabels}
              handleDropdownClick={this.props.handleDropdownClick}
              values={this.props.values}
              labelSearch={this.props.labelSearch}
              padding="pl-3"
            />
            :
            null
        }
      </AccordionItemPanel>
    </AccordionItem>;
  }
}


export class NestedDropDownMenuOptions extends Component {
  state = {
    open: {}
  }

  handleAccordionChange = e => {
    this.setState({open: e.length > 0 ? {[e[0]]: true} : {}});
  }

  handleDropdownClick = e => {
    e.stopPropagation();
    this.props.handleDropdownClick(e);
  }

  render() {
    let selectableLabels;
    if (this.props.labelSearch === "") {
      selectableLabels = this.props.availableLabels
        .map(x => {
          return {
            name: x.name,
            id: x.id,
            categories: x.categories
          };
        })
        .filter(
          x => !this.props.values?.includes(x.name)
        );
    } else {
      selectableLabels = Object.keys(this.props.flattenedAvailableLabels)
        .filter(
          x => this.props.flattenedAvailableLabels[x].toLowerCase()?.includes(this.props.labelSearch.toLowerCase())
        ).map(
          x => {
            return {
              name: this.props.flattenedAvailableLabels[x],
              id: x,
            }
          }
        );
    }

    return (
      <Accordion allowZeroExpanded onChange={this.handleAccordionChange} id="suggested_places_categories">
        {
          selectableLabels.map(category =>
            this.props.labelSearch.length > 0
              ?
              <button
                key={category.id}
                id={category.id}
                className="hover:bg-gray-200 text-left block rounded w-full px-1.5"
                onClick={this.props.handleDropdownClick}
              >
                {category.name}
              </button>
              :
              <NestedAccordionItem
                key={category.id}
                className={this.props.padding}
                category={category}
                open={this.state.open}
                onClick={this.handleDropdownClick}
                flattenedAvailableLabels={this.props.flattenedAvailableLabels}
                handleDropdownClick={this.props.handleDropdownClick} values={this.props.values}
                labelSearch={this.props.labelSearch}
              />
          )
        }
      </Accordion>
    )
  }
}